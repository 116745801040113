import routes from "../../consts/routes";
import palette from "../../consts/palette";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import photo1 from "../assets/galery/foto1.webp";
import photo2 from "../assets/galery/foto2.webp";
import photo3 from "../assets/galery/foto3.webp";
import photo4 from "../assets/galery/foto4.webp";
import photo5 from "../assets/galery/foto5.webp";
import photo6 from "../assets/galery/foto6.webp";
import photo7 from "../assets/galery/foto7.webp";
import photo8 from "../assets/galery/foto8.webp";
import photo9 from "../assets/galery/foto9.webp";
import photo10 from "../assets/galery/foto10.webp";
import photo11 from "../assets/galery/foto11.webp";
import photo12 from "../assets/galery/foto12.webp";
import photo13 from "../assets/galery/foto13.webp";
import photo14 from "../assets/galery/foto14.webp";
import photo15 from "../assets/galery/foto15.webp";
import photo16 from "../assets/galery/foto16.webp";
import photo17 from "../assets/galery/foto17.webp";
import photo19 from "../assets/galery/foto19.webp";
import photo20 from "../assets/galery/foto20.webp";
import photo21 from "../assets/galery/foto21.webp";
import photo22 from "../assets/galery/foto22.webp";
import photo23 from "../assets/galery/foto23.webp";
import photo24 from "../assets/galery/foto24.webp";
import photo25 from "../assets/galery/foto25.webp";
import photo26 from "../assets/galery/foto26.webp";
import photo27 from "../assets/galery/foto27.webp";
import photo28 from "../assets/galery/foto28.webp";
import photo29 from "../assets/galery/foto29.webp";
import photo30 from "../assets/galery/foto30.webp";
import photo31 from "../assets/galery/foto31.webp";
import photo32 from "../assets/galery/foto32.webp";
import photo33 from "../assets/galery/foto33.webp";
import photo34 from "../assets/galery/foto34.webp";
import photo35 from "../assets/galery/foto35.webp";
import photo20231 from "../assets/galery/20231.webp";
import photo20232 from "../assets/galery/20232.webp";
import photo20233 from "../assets/galery/20233.webp";
import photo20234 from "../assets/galery/20234.webp";
import photo20235 from "../assets/galery/20235.webp";
import photo20236 from "../assets/galery/20236.webp";
import photo20237 from "../assets/galery/20237.webp";
import photo20238 from "../assets/galery/20238.webp";
import photo20239 from "../assets/galery/20239.webp";
import photo202310 from "../assets/galery/202310.webp";
import photo202311 from "../assets/galery/202311.webp";
import photo202312 from "../assets/galery/202312.webp";
import photo202313 from "../assets/galery/202313.webp";
import photo202314 from "../assets/galery/202314.webp";
import photo202315 from "../assets/galery/202315.webp";
import photo202316 from "../assets/galery/202316.webp";
import photo202317 from "../assets/galery/202317.webp";
import photo202318 from "../assets/galery/202318.webp";
import photo202319 from "../assets/galery/202319.webp";
import photo202320 from "../assets/galery/202320.webp";
import photo202321 from "../assets/galery/202321.webp";
import photo202322 from "../assets/galery/202322.webp";
import photo202323 from "../assets/galery/202323.webp";
import photo202324 from "../assets/galery/202324.webp";
import photo202325 from "../assets/galery/202325.webp";
import photo202326 from "../assets/galery/202326.webp";
import photo202327 from "../assets/galery/202327.webp";
import photo202328 from "../assets/galery/202328.webp";
import photo202329 from "../assets/galery/202329.webp";
import photo202330 from "../assets/galery/202330.webp";
import photo202331 from "../assets/galery/202331.webp";
import photo202332 from "../assets/galery/202332.webp";
import photo202333 from "../assets/galery/202333.webp";
import photo202334 from "../assets/galery/202334.webp";
import photo202335 from "../assets/galery/202335.webp";
import photo202336 from "../assets/galery/202336.webp";
import photo202337 from "../assets/galery/202337.webp";
import photo202338 from "../assets/galery/202338.webp";
import photo202339 from "../assets/galery/202339.webp";
import photo202340 from "../assets/galery/202340.webp";
import photo2023iccl from "../assets/galery/2023iccl.webp";
import photo2023iccl1 from "../assets/galery/2023iccl1.webp";
import photo2023iccl2 from "../assets/galery/2023iccl2.webp";
import photo2023iccl3 from "../assets/galery/2023iccl3.webp";





function Galery() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          height: "50vh",
          mb: "2vh",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Typography
          sx={{ typography: { xs: "h4", md: "h2" } }}
          align="center"
          mt="2vh"
        >
          {t("galery")}
        </Typography>
        <Divider
          sx={{
            mb: "2vh",
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <img
          src={photo10}
          style={{
            width: "60vw",
            height: "40vh",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
      <Typography
        sx={{ typography: { xs: "h6", md: "h4" } }}
        align="center"
        mt="2vh"
      >
        {t("2023")}
      </Typography>
      <Divider
        sx={{
          mb: "2vh",
          width: "90vw",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />

      <ImageList sx={{ mx: "15vw" }} cols={matches ? 3 : 1} rowHeight={450}>
      <ImageListItem key="photo202340">
          <img
            src={photo202340}
            alt="photo202340"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202339">
          <img
            src={photo202339}
            alt="photo202339"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202338">
          <img
            src={photo202338}
            alt="photo202338"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202337">
          <img
            src={photo202337}
            alt="photo202337"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202336">
          <img
            src={photo202336}
            alt="photo202336"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202335">
          <img
            src={photo202335}
            alt="photo202335"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202334">
          <img
            src={photo202334}
            alt="photo202334"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202333">
          <img
            src={photo202333}
            alt="photo202333"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202332">
          <img
            src={photo202332}
            alt="photo202332"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202331">
          <img
            src={photo202331}
            alt="photo202331"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202330">
          <img
            src={photo202330}
            alt="photo202330"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202329">
          <img
            src={photo202329}
            alt="photo202329"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202328">
          <img
            src={photo202328}
            alt="photo202328"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202327">
          <img
            src={photo202327}
            alt="photo202327"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202326">
          <img
            src={photo202326}
            alt="photo202326"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202325">
          <img
            src={photo202325}
            alt="photo202325"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202324">
          <img
            src={photo202324}
            alt="photo202324"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202323">
          <img
            src={photo202323}
            alt="photo202323"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202322">
          <img
            src={photo202322}
            alt="photo202322"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202321">
          <img
            src={photo202321}
            alt="photo202321"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202320">
          <img
            src={photo202320}
            alt="photo202320"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202319">
          <img
            src={photo202319}
            alt="photo202319"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202318">
          <img
            src={photo202318}
            alt="photo202318"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202317">
          <img
            src={photo202317}
            alt="photo202317"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202316">
          <img
            src={photo202316}
            alt="photo202316"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202315">
          <img
            src={photo202315}
            alt="photo202315"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      <ImageListItem key="photo202314">
          <img
            src={photo202314}
            alt="photo202314"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      
      <ImageListItem key="photo202310">
          <img
            src={photo202310}
            alt="photo202310"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo202311">
          <img
            src={photo202311}
            alt="photo202311"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo202312">
          <img
            src={photo202312}
            alt="photo202312"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo202313">
          <img
            src={photo202313}
            alt="photo202313"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo2023iccl">
          <img
            src={photo2023iccl}
            alt="photo2023iccl"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo2023iccl1">
          <img
            src={photo2023iccl1}
            alt="photo2023iccl1"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo2023iccl2">
          <img
            src={photo2023iccl2}
            alt="photo2023iccl2"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo2023iccl3">
          <img
            src={photo2023iccl3}
            alt="photo2023iccl3"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo20238">
          <img
            src={photo20238}
            alt="photo20238"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="photo20232">
          <img
            src={photo20232}
            alt="photo20232"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20233">
          <img
            src={photo20233}
            alt="photo20233"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20234">
          <img
            src={photo20234}
            alt="photo20234"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20235">
          <img
            src={photo20235}
            alt="photo20235"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20236">
          <img
            src={photo20236}
            alt="photo20236"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20237">
          <img
            src={photo20237}
            alt="photo20237"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20239">
          <img
            src={photo20239}
            alt="photo20239"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="photo20231">
          <img
            src={photo20231}
            alt="photo20231"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      </ImageList>
      <Typography
        sx={{ typography: { xs: "h6", md: "h4" } }}
        align="center"
        mt="2vh"
      >
        {t("2022")}
      </Typography>
      <Divider
        sx={{
          mb: "2vh",
          width: "90vw",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Box sx={{ mx: "15vw" }}>
        <ImageList
          // sx={{ minHeight: "10vh" }}
          cols={matches ? 3 : 1}
          rowHeight={450}
        >
          <ImageListItem key="lakephoto4">
            <img
              src={photo4}
              alt="lakephoto4"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto5">
            <img
              src={photo5}
              alt="lakephoto5"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto6">
            <img
              src={photo6}
              alt="lakephoto6"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto7">
            <img
              src={photo7}
              alt="lakephoto7"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto8">
            <img
              src={photo8}
              alt="lakephoto8"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto9">
            <img
              src={photo9}
              alt="lakephoto9"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>

          <ImageListItem key="lakephoto11">
            <img
              src={photo11}
              alt="lakephoto11"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto12">
            <img
              src={photo12}
              alt="lakephoto12"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto13">
            <img
              src={photo13}
              alt="lakephoto13"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto14">
            <img
              src={photo14}
              alt="lakephoto14"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto15">
            <img
              src={photo15}
              alt="lakephoto15"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto16">
            <img
              src={photo16}
              alt="lakephoto16"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto17">
            <img
              src={photo17}
              alt="lakephoto17"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto19">
            <img
              src={photo19}
              alt="lakephoto19"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto20">
            <img
              src={photo20}
              alt="lakephoto20"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>

          <ImageListItem key="lakephoto21">
            <img
              src={photo21}
              alt="lakephoto21"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto22">
            <img
              src={photo22}
              alt="lakephoto22"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto23">
            <img
              src={photo23}
              alt="lakephoto23"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto24">
            <img
              src={photo24}
              alt="lakephoto24"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto25">
            <img
              src={photo25}
              alt="lakephoto25"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto26">
            <img
              src={photo26}
              alt="lakephoto26"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto27">
            <img
              src={photo27}
              alt="lakephoto27"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto28">
            <img
              src={photo28}
              alt="lakephoto28"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
          <ImageListItem key="lakephoto19">
            <img
              src={photo29}
              alt="lakephoto29"
              style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
            />
          </ImageListItem>
        </ImageList>
      </Box>
      <Typography
        sx={{ typography: { xs: "h6", md: "h4" } }}
        align="center"
        mt="2vh"
      >
        {t("2021")}
      </Typography>
      <Divider
        sx={{
          mb: "2vh",
          width: "90vw",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <ImageList sx={{ mx: "15vw" }} cols={matches ? 3 : 1} rowHeight={450}>
        <ImageListItem key="lakephoto30">
          <img
            src={photo30}
            alt="lakephoto30"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>

        <ImageListItem key="lakephoto31">
          <img
            src={photo31}
            alt="lakephoto31"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto32">
          <img
            src={photo32}
            alt="lakephoto32"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto33">
          <img
            src={photo33}
            alt="lakephoto33"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto34">
          <img
            src={photo34}
            alt="lakephoto34"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto35">
          <img
            src={photo35}
            alt="lakephoto35"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto1">
          <img
            src={photo1}
            alt="lakephoto1"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto2">
          <img
            src={photo2}
            alt="lakephoto2"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto3">
          <img
            src={photo3}
            alt="lakephoto3"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto4">
          <img
            src={photo4}
            alt="lakephoto4"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto5">
          <img
            src={photo5}
            alt="lakephoto5"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
        <ImageListItem key="lakephoto6">
          <img
            src={photo6}
            alt="lakephoto6"
            style={{ margin: "auto", maxHeight: 400, maxWidth: 400 }}
          />
        </ImageListItem>
      </ImageList>
    </Stack>
  );
}

export default Galery;
