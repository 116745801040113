import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import palette from "../../consts/palette";
import { useTranslation } from "react-i18next";
import routes from "../../consts/routes";
import { Box, IconButton, Menu, MenuItem, Popover } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { GB, PL } from "country-flag-icons/react/3x2";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/logostrupino.webp";

function ResponsiveAppBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguageHandler = (lang: string | undefined) => {
    setAnchorEl(null);
    i18n.changeLanguage(lang);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [hamburgerEl, setHamburgerEl] =
    React.useState<HTMLButtonElement | null>(null);
  const handleHamburgerOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHamburgerEl(event.currentTarget);
  };
  const handleHamburgerClose = () => {
    setHamburgerEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar position="static" sx={{ backgroundColor: palette.green }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <IconButton
              onClick={() => navigate(`/${routes.home}`)}
              sx={{ ...navigateButtonStyling, fontSize: "120%" }}
            >
              <img
                src={Logo}
                alt="strupino lake logo"
                style={{ width: "15vw", alignSelf: "center" }}
              />
            </IconButton>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.galery}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("galery")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.events}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("events")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.stations}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("stations")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.termsOfUse}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("termsOfUse")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.pricing}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("pricing")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.booking}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("booking")}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="a"
              onClick={() => navigate(`/${routes.contact}`)}
              align="center"
              sx={{ ...navigateButtonStyling, ...navigateTextStyling }}
            >
              {t("contact")}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleHamburgerOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={hamburgerEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(hamburgerEl)}
              onClose={handleHamburgerClose}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.galery}`);
                }}
              >
                <Typography textAlign="center">{t("galery")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.events}`);
                }}
              >
                <Typography textAlign="center">{t("events")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.stations}`);
                }}
              >
                <Typography textAlign="center">{t("stations")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.termsOfUse}`);
                }}
              >
                <Typography textAlign="center">{t("termsOfUse")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.pricing}`);
                }}
              >
                <Typography textAlign="center">{t("pricing")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.booking}`);
                }}
              >
                <Typography textAlign="center">{t("booking")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleHamburgerClose();
                  navigate(`/${routes.contact}`);
                }}
              >
                <Typography textAlign="center">{t("contact")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Box
              onClick={() => navigate(`/${routes.home}`)}
              sx={{ ...navigateButtonStyling, fontSize: "8%", width: "100%" }}
            >
              <img
                src={Logo}
                alt="strupino lake logo at p"
                style={{ width: "50vw", alignSelf: "center" }}
              />
            </Box>
          </Box>
          <Box>
            <IconButton onClick={handleClick}>
              <LanguageIcon sx={{ color: "white" }} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handleClose}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: palette.beige,
                }}
              >
                <IconButton onClick={() => changeLanguageHandler("en")}>
                  <GB style={{ height: "25px" }} />
                </IconButton>
                <IconButton onClick={() => changeLanguageHandler("pl")}>
                  <PL style={{ height: "25px" }} />
                </IconButton>
              </Box>
            </Popover>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;

const navigateButtonStyling = {
  fontFamily: "monospace",
  fontWeight: 700,
  letterSpacing: ".1rem",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
};
const navigateTextStyling = {
  margin: "auto",
  fontSize: "130%",
};
