import routes from "../../consts/routes";
import palette from "../../consts/palette";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import terms from "../../consts/terms";
import { useState } from "react";
import ScrollToTop from "react-scroll-to-top";

function Terms() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subPages = Object.keys(terms);
  const [displayedTerms, setDisplayedTerms] = useState<string>(subPages[0]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        minHeight: "100vh",
      }}
    >
      <Typography
        sx={{ typography: { xs: "h4", md: "h2" } }}
        align="center"
        mt="2vh"
      >
        {t("termsOfUse")}
      </Typography>
      <Divider
        sx={{
          width: "70vw",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Box
        sx={{
          mx: "auto",
          my: 5,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {subPages.map((item) => (
          <Button
            key={item}
            variant={item !== displayedTerms ? "contained" : undefined}
            color="success"
            size="large"
            sx={{
              borderRadius: 0,
              border: "none",
              boxShadow:
                item !== displayedTerms
                  ? "none"
                  : "inset 0px 0px 12px -1px rgba(66, 68, 90, 1)",
              width: "fit-content",
              margin: "auto",
              minWidth: 300,
            }}
            onClick={() => setDisplayedTerms(item)}
          >
            {item}
          </Button>
        ))}
      </Box>
      <Paper
        sx={{
          p: 3,
          alignSelf: "center",
          width: { xs: "85%", md: "60%" },
          borderRadius: "20px",
          mb: 2,
        }}
      >
        <Stack>
          {Object.values(terms[displayedTerms as keyof typeof terms]).map(
            (item, index) => (
              <Typography
                key={item}
                variant="subtitle1"
                my={1}
                alignSelf="left"
                ml={
                  item.includes("_")
                    ? Number(item.split("_").length - 1) * 3
                    : 0.3
                }
                sx={{
                  fontWeight: item.includes("$") ? "bold" : "",
                }}
              >
                {t(item)}
              </Typography>
            )
          )}
        </Stack>
      </Paper>
      <ScrollToTop smooth style={{ backgroundColor: palette.green }} />
    </Box>
  );
}

export default Terms;
