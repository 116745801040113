import routes from "../../consts/routes";
import palette from "../../consts/palette";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MapComponent from "../common/MapComponent";
import ModalComponent from "../common/ModalComponent";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import config from "../../consts/config";

const stations: string[] = [
  "station1",
  "station2",
  "station3",
  "station4",
  "station5",
  "station6",
  "station7",
  "station8",
  "station9",
  "station10",
  "station11",
  "station12",
  "station13",
  "station14",
  "station15",
  "station16",
  "station17",
  "station18",
  "station19",
  "station20",
  "station21",
  "station22",
  "station23",
  "station24",
  "station25",
  "station26",
  "station27",
  "station28",
  "station29",
  "station30",
  "station31",
  "station32",
  "station33",
  "station34",
  "station35",
  "station36",
  "station37",
  "station38",
  "station39",
  "station40",
  "station41",
  "station42",
  "station43",
  "station44",
  "station45",
  "station46",
  "station47",
  "station48",
];

function Stations() {
  const shouldDisableStationDate = (date: Dayjs) => {
    return reservedDatesForStation.includes(date.toString());
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [stationId, setStationId] = useState<number | null>(null);
  const onStationClick = (id: number) => setStationId(id);
  const onStationModalClose = () => setStationId(null);

  const todayDate = dayjs();
  const [startDate, setStartDate] = useState<Dayjs | undefined | null>(
    todayDate
  );
  const [endDate, setEndDate] = useState<Dayjs | undefined | null>(startDate);

  const [reservedDatesForStation, setReservedDatesForStation] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    const getReservationForDates = async () => {
      const stationDates = axios.get(
        config.apiIp + "/reservation/getReservationForDates",
        { params: { station_id: stationId } }
      );
    };
    getReservationForDates();
  }, []);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        pb: 2,
        m: 3,
        borderRadius: "25px",
      }}
    >
      <Box sx={{ width: "90%", mx: "auto" }}>
        <Typography
          sx={{ typography: { xs: "h4", md: "h2" } }}
          align="center"
          mt="2vh"
        >
          {t("stations")}
        </Typography>
        <Divider
          sx={{
            mb: 3,
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DatePicker"]}
          sx={{ display: "flex", justifyContent: "space-evenly", m: 3 }}
        >
          <DemoItem label={t("startDate")}>
            <DatePicker
              defaultValue={startDate}
              minDate={todayDate!}
              sx={{ minWidth: "240px", backgroundColor: "white" }}
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              shouldDisableDate={shouldDisableStationDate}
            />
          </DemoItem>
          <DemoItem label={t("endDate")}>
            <DatePicker
              defaultValue={endDate}
              minDate={startDate!}
              sx={{ minWidth: "240px", backgroundColor: "white" }}
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              shouldDisableDate={shouldDisableStationDate}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider> */}
      <Box sx={{ width: "90%", mx: "auto" }}>
        <MapComponent onStationClick={onStationClick} />
      </Box>
      <Modal open={Boolean(stationId)} onClose={onStationModalClose}>
        <Box sx={style}>
          <ModalComponent stationId={Number(stationId)}></ModalComponent>
        </Box>
      </Modal>
    </Paper>
  );
}

export default Stations;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
