import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

export interface NewsCardInterface {
  title: string;
  date: string;
  paragraph: string;
  image: string;
}

function NewsCard({ title, date, paragraph, image }: NewsCardInterface) {
    const matches = useMediaQuery("(min-width:400px)");

  return (
    <Card
      sx={{
        width: "70vw",
        minHeight: "400px",
        borderRadius: "20px",
        margin: "auto",
      }}
    >
      <Box p={2}>
        <Typography
          sx={{ typography: { xs: "h6", md: "h4" } }}
          align="center"
          width="100%"
        >
          {title}
        </Typography>
        <Divider sx={{ m: 2 }} />
        <Grid container>
          <Grid item xs={12} md={4} sx={{ p: 2 }}>
            
            <img
              alt={`${title}`}
              style={{
                display: "block",
                maxWidth: matches?"300px":"200px",
                maxHeight: "300px",
                margin: "auto",
              }}
              src={image}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Typography
              sx={{ typography: { xs: "body2", md: "body1" } }}
              align="center"
              marginLeft="auto"
              marginRight="auto"
              minWidth="60%"
              maxWidth="90%"
            >
              <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
            </Typography>
            <Divider sx={{ width: "60%", alignSelf: "center", my: 0.3 }} />
            <Typography
              variant="subtitle1"
              align="center"
              justifySelf="flex-end"
              mb={2}
              maxWidth="60%"
            >
              {date}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default NewsCard;
