import NavbarComponent from "./components/common/NavbarComponent";
import "./App.css";
import { Box } from "@mui/material";
import palette from "./consts/palette";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import routes from "./consts/routes";
import FooterComponent from "./components/common/FooterComponent";
import Booking from "./components/subpages/Booking";
import Contact from "./components/subpages/Contact";
import Galery from "./components/subpages/Galery";
import HomePage from "./components/subpages/HomePage";
import News from "./components/subpages/News";
import Prices from "./components/subpages/Prices";
import Stations from "./components/subpages/Stations";
import Terms from "./components/subpages/Terms";

const FrontPage = () => (
  <>
    <NavbarComponent />
    <Outlet />
    <FooterComponent />
  </>
);

function App() {
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: palette.beige,
      }}
    >
      <Routes>
        <Route path="/" element={<FrontPage />}>
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.galery} element={<Galery />} />
          <Route path={routes.events} element={<News />} />
          <Route path={routes.stations} element={<Stations />} />
          <Route path={routes.termsOfUse} element={<Terms />} />
          <Route path={routes.pricing} element={<Prices />} />
          <Route path={routes.booking} element={<Booking />} />
          <Route path={routes.contact} element={<Contact />} />

          <Route
            path="*"
            element={<Navigate to={`/${routes.home}`} replace />}
          />
          <Route
            path=""
            element={<Navigate to={`/${routes.home}`} replace />}
          />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
