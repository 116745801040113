import * as React from "react";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


interface ModalComponentInterface {
  stationId: number;
}

export default function ModalComponent({ stationId }: ModalComponentInterface) {
  const { t } = useTranslation();
  const photoName1 =
    require(`../assets/stationsPhotos/station${stationId}1.webp`)
const photoName2 =
  require(`../assets/stationsPhotos/station${stationId}2.webp`)

  return (
    <Box>
      <Typography
        id="transition-modal-title"
        variant="h6"
        component="h2"
        align="center"
      >
        Stanowisko {stationId}
      </Typography>
      <Box sx={imageStyling(photoName1)} />
      <Divider
        sx={{
          my: "2vh",
          width: "80%",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Box sx={imageStyling(photoName2)} />
      <Divider
        sx={{
          my: "1vh",
          width: "80%",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Box textAlign="center">
        <Button
          color="success"
          size="large"
          variant="contained"
          sx={{
            borderRadius: 0,
            border: "none",
            minWidth: 200,
          }}
        >
          <Link
            to="/rezerwacje"
            style={{color:"white"}}
          >
            Przejdź do rezerwacji
          </Link>
        </Button>
      </Box>
      <Typography id="transition-modal-description" sx={{ mt: 2 }}></Typography>
    </Box>
  );

}
function imageStyling(photoName:string){
     const sx = {
       minHeight: "200px",
       backgroundSize: "cover",
       backgroundPosition: "center" ,
        pb: "10%",
       backgroundImage: `url(${photoName})`,
     };
    return sx

}
