import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const plTranslation = {
  address: "Adres:",
  telephone: "Telefon:",
  strupinoLake: "Jezioro Strupino",
  galery: "Galeria",
  events: "Aktualnosci",
  stations: "Stanowiska",
  termsOfUse: "Regulamin",
  pricing: "Cennik",
  booking: "Rezerwacje",
  contact: "Kontakt",
  prices: "Cennik",
  pricesDailyIntro: "Licencja Dniowa (Od świtu do zmierzchu)",
  pricesDailyIntro3: "",
  pricesSpinningIntro: "",
  pricesCarpIntro2: "",
  pricesCarpIntro3: "",
  pricesDailyMain: "Połów od świtu do zmierzchu",
  pricesDailyRod2: "max 2 wędki - 40zł",
  pricesDailyRod3: "max 3 wędki - 50zł",
  pricesDobaIntro: "Licencja Karpiowa (dobowa)",
  pricesDobaRod2: "max 2 wędki - 60zł",
  pricesDobaRod3: "max 3 wędki - 70zł",
  pricesSpinningIntro: "Licencja Spinngowa (z brzegu)",
  pricesSpinningRod2: "Od świtu do zmierzchu",
  pricesSpinningRod3: "40zł",
  pricesMakeReservation: "Zarezerwuj",
  pricesTerms: "Regulamin",
  pricesLine1:
    "Doba liczona jest od godziny 14:00 do godziny 13:30 dnia następnego.",
  pricesLine2:
    "Licencje mozna zakupić u opiekuna łowiska Tadeusza Kapickiego, tel. 693 823 261",

  contact: "Kontakt",
  introText: "",
  introSubtitle: "Inhaluj swojego konia zobacz propdukty brr",
  introButton: "Zobacz sklep",
  shopHeader: "Nasze produkty",
  shopMaskCardIntro: "Maska do inhalacji",
  shopMaskCardParagraph:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  shopMaskCardButton: "Kup",
  learnMore: "Dowiedz się więcej",
  homeCardTitle: "Inhalacja kuca",
  homeCardText:
    "Inhalations are an important part of treating a horse with respiratory disease. Your veterinarian may prescribe an inhaled steroid, which works better than steroids in many horses, intravenously or intramuscularly.",
  customizeInfoHeader: "Maska skrtojonja dla twojego konia",
  customizeInfoText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  customizeInfoSubtitle: "Maska jakiej chcesz",
  customizeCallToAction: "Dostosuj swoja maske teraz",
  customizeButton: "Customize mask",
  whyToInhale: "Why to inhale",
  whyToInhaleHeader1: "Dla sily",
  whyToInhaleHeader2: "Dla zdrowia",
  whyToInhaleHeader3: "Dla speed",
  whyToInhaleSubstring1:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  whyToInhaleSubstring2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  whyToInhaleSubstring3:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  chooseSize: "Wybierz rozmiar",
  addText: "Add text",
  topic: "Temat",
  message: "Wiadomość",
  sendMessage: "Wyślij wiadomość",

  def1: "1. Poniższy regulamin obowiązuje od dnia 24 kwietnia 2022 roku.",
  def2: "2. Ewentualne zmiany w niniejszym regulaminie będą publikowane na stronie internetowej www.strupino.pl i wchodzą w życie z dniem ich opublikowania.",
  def3: "3. Ilekroć w niniejszym regulaminie jest mowa o:",
  _def4:
    "I. Właścicielu łowiska – rozumie się przez to osobę uprawnioną do reprezentowania i prowadzenia spraw GRANT sp. z o.o.;",
  _def5:
    "II. Opiekunie łowiska – rozumie się przez to osobę wyznaczoną przez właściciela łowiska do opieki nad łowiskiem, uprawnioną do wydawania zezwoleń na wędkowanie oraz przeprowadzania kontroli w tym zakresie;",
  _def6:
    "III. Zezwoleniu na wędkowanie – rozumie się przez to imienny dokument (licencja dzienna/dobowa) wydawany odpłatnie przez właściciela łowiska lub opiekuna łowiska, uprawniający do połowu ryb na terenie jeziora Strupino, na określony w zezwoleniu czas;  ",
  _def7:
    "IV. Stanowisku – rozumie się przez to wydzielone miejsce , pomost, znajdujący się na terenie jeziora Strupino, z którego następuje połów ryb.",
  def8: "4. Teren całego jeziora stanowi teren prywatny.",
  def9: "5. Wędkujący na wodach jeziora Strupino zobowiązani są do przestrzegania przepisów wynikających z ustawy z dnia 18 kwietnia 1985 roku o rybactwie śródlądowym (t.j. Dz. U. z 2019 r. poz. 2168 z późn.zm), rozporządzeń do ww. ustawy, przepisów powszechnie obowiązujących oraz postanowień niniejszego regulaminu.",
  def10:
    "6. Wędkowanie na jeziorze Strupino dozwolone jest wyłącznie po zapoznaniu się z niniejszym regulaminem i opłaceniu zezwolenia na wędkowanie.",
  def11:
    "7. Zezwolenie na wędkowanie należy wykupić u właściciela lub opiekuna łowiska lub w wybranych punktach sprzedaży. Przy zakupie właściciel lub opiekun łowiska przydziela kupującemu stanowisko, na którym będzie uprawniony do wędkowania. Zabrania się samodzielnej zmiany przydzielonego stanowiska.",
  def12:
    "8. Ze względów bezpieczeństwa korzystanie ze stanowiska musi odbywać się z zachowaniem szczególnej ostrożności, w szczególności:",
  _def13: "  I. osoby nieletnie muszą być pod opieką osoby dorosłej; ",
  _def14:
    "  II. W porze nocnej zaleca się korzystanie z kamizelek ratunkowych.",
  def15:
    "9. Na jedną osobę łowiącą przysługuje jedno zezwolenie na wędkowanie w jeziorze Strupino (nie dotyczy towarzyszących dzieci do lat 12).",
  def16:
    "10 . Aby zezwolenie na wędkowanie było ważne, konieczne jest posiadanie dowodu osobistego lub innego dokumentu ze zdjęciem pozwalającego na potwierdzenie tożsamości osoby.",
  def17:
    "11. Dozwolone jest łowienie metodą gruntową, spławikową (max. 2 wędki), lub spinningową (max. 1 wędka).",
  def18:
    "12. W przypadku złowienia Karpia lub Szczupaka, bez względu na wielkość złowionej ryby – należy wypuścić taką rybę z powrotem do wody (zasada „no kill”).",
  def19:
    "13. Osoby łamiące regulamin, limity połowów, oraz nieprzestrzegające okresów ochronnych ryb zostaną wydalone z terenu jeziora Strupino, bez zwrotu opłat oraz bez możliwości ponownego przyjazdu na teren jeziora.",
  def20:
    "14. W każdym czasie właściciel łowiska, Straż Rybacka, Policja, Straż Leśna oraz upoważnione osoby (wymienione w pkt 20 niniejszego regulaminu) mogą dokonać kontroli zezwolenia na połów ryb. Wykupienie zezwolenia na wędkowanie jest jednoznaczne z wyrażeniem zgody na przeprowadzenie takiej kontroli.",
  def21:
    "15 W przypadku odmowy przez wędkującego okazania do kontroli zezwolenia na wędkowanie wraz z dowodem osobistym lub innym dokumentem ze zdjęciem pozwalającym na potwierdzenie jego tożsamości osobom wymienionym w pkt 20 regulaminu, wezwany zostanie uprawniony do tego organ państwowy.",
  def22:
    "16. Właściciel łowiska ma prawo zamknąć akwen na czas zawodów wędkarskich lub innych imprez na łowisku. Wszelkie informacje w tym zakresie udostępniane będą na stronie internetowej pod adresem www.strupino.pl.",
  def23:
    "17. Na terenie jeziora Strupino zabrania się nęcić łowiska surowym ziarnem w tym: kukurydzą, grochem, orzechem tygrysim, łubinem, itp., a także surowym i zepsutym ziarnem. Opiekunowie łowiska posiadają prawo wglądu do zanęt.",
  def24:
    "18. Po skończonym łowieniu zabrania się wyrzucania do wody reszty niezużytej zanęty. Dobowy limit użycia zanęty wynosi 3kg.",
  def25:
    "19. W celu zapewnienia porządku oraz bezpieczeństwa mienia i osób przebywających na terenie prywatnym Jeziora Strupino, a także w celu ochrony przeciwpożarowej i przeciwpowodziowej, GRANT sp. z o.o. prowadzi monitoring wizyjny. Obszar monitorowany obejmuje linię brzegową oraz jezioro, tj. teren prywatny należący do spółki. Więcej informacji na temat przetwarzania danych osobowych w postaci wizerunku zarejestrowanego przez monitoring znajduje się na stronie internetowej www.strupino.pl.",
  def26: "20. Dodatkowo na terenie jeziora Strupino zabrania się:",
  _def27:
    "I. Rozpoczęcia wędkowania bez uprzedniego wykupienia zezwolenia na wędkowanie nad jeziorem, chyba że zainteresowany wykupieniem zezwolenia otrzyma na to zgodę od opiekuna łowiska po uprzednim uzgodnieniu z nim sposobu i termin uregulowania opłaty za zezwolenie na wędkowanie;",
  _def28: "II. Zaśmiecania terenu łowiska, linii brzegowej i zbiornika;",
  _def29:
    "III. Niszczenia terenów zielonych w tym wycinania, łamania drzew i krzewów;",
  _def30: "IV. Rozpalania ognisk;",
  _def31: "V. Głośnego zachowania się.",
  def32:
    "21. Osobami upoważnionymi przez właściciela jeziora Strupino do kontroli są:",
  _def33: "I.Tadeusz Kapicki;",
  _def34: "II. Tomasz Siarczyński",
  _def35: "III. Dawid Siarczyński;",
  _def36: "IV. Marek Brylowski;",
  _def37: "V. Danuta Brylowska.",
  _def38: "VI. Mariusz Kutella",
  def39:
    "22. Wszelkie sprawy nieobjęte tym regulaminem należy konsultować z opiekunem lub właścicielem łowiska.",
  _def40: "I. Kontakt do opiekuna łowiska: Tadeusz Kapicki tel. 693 823 261;",
  _def41:
    "II. Kontakt do właściciela łowiska: Tomasz Siarczyński tel. 604 443 204.",
  def42:
    "23. W sytuacjach spornych, ostateczna decyzja zawsze pozostaje w gestii właściciela lub opiekuna łowiska.",
  def43:
    "24. Na terenie jeziora obowiązuje zakaz nurkowania, połowu z kuszy oraz połowu z łódki.",
  def44:
    "25. Połów z łodzi (spinning) możliwy jest jedynie po wcześniejszym umówieniu się na termin połowu z właścicielem lub opiekunem łowiska. Jednocześnie każda osoba znajdująca się na łodzi, zobowiązana jest do niekorzystania z niej pod wpływem alkoholu lub innych środków odurzających, nieudostępniania łodzi osobom nieletnim bez nadzoru opiekuna oraz do ubrania kamizelki ratunkowej.",
  def45:
    "26. Właściciel łowiska oraz opiekun łowiska nie ponoszą odpowiedzialności za wypadki, kradzieże i wszelkie zdarzenia losowe, powstałe na terenie łowiska Strupino, a także za rzeczy pozostawione na terenie łowiska przez osoby wkraczające na jego teren. ",
  def46: "27. Limity połowowe – wymiary i okresy ochronne ryb:",
  _def47:
    "I. Dzienny limit połowu ryb na jeziorze Strupino wynosi łącznie 5 kg złowionych ryb (nie dotyczy leszcza, krąpia, amura, karasia srebrzystego oraz suma).",
  _def48:
    "II. Zabronione jest dokonywanie podmiany ryby wcześniej przeznaczonej do zabrania. Wymiary ochronne dla poszczególnych ryb wynoszą:",
  __def49: "a. płoć – wymiar ochronny do 15 cm;",
  __def50: "b. leszcz (bez wymiaru ochronnego);",
  __def51: "c. krąp i karaś srebrzysty (bez ograniczeń);",
  __def52: "d. karaś pospolity („złoty”) – wymiar ochronny do 15 cm;",
  __def53: "e. karp – wypuszczamy z powrotem do wody (zasada „no kill”);",
  __def54: "f. okoń – wymiar ochronny do 18 cm;",
  __def55: "g. lin – wymiar ochronny do 25 cm;",
  __def56:
    "h.sieja – wymiar ochronny do 35 cm (okres ochronny 15 października do 31 grudnia ) limit 3 szt.;  ",
  __def57: "i. szczupak – wypuszczamy z powrotem do wody (zasada „no kill”);",
  __def58:
    "j. sum – wymiar ochronny do 70 cm (okres ochronny 1 stycznia – 31 maja);",
  __def59:
    "k. węgorz – limit 2 szt. wymiar ochronny do 50 cm (okres ochronny od 1 grudnia do 31 marca).",
  def60:
    "28. Zabrania się nęcenia łowiska ziarnem, surowym i gotowanym:  kukurydzą, grochem, orzechem tygrysim, łubinem, etc.",
  def61:
    "29. Dopuszcza się jedynie nęcenie gotowanymi ziarnami konopi oraz miękką, „puszkową” kukurydzą. Dobowy limit użycia zanęty wynosi 3kg.",
  def62: "30. Na terenie łowiska obowiązuje zakaz kąpieli.",
  def63:
    "31. Teren obiektu nie jest kąpieliskiem w rozumieniu ustawy z dnia 20 lipca 2017 r. Prawo wodne (t.j. Dz. U. z 2021r. poz. 2233, z późn.zm), dlatego kąpanie się w jeziorze następuje na własne ryzyko i odpowiedzialność. ",
  def64:
    "32. Nieprzestrzeganie powyższego regulaminu, wymiarów i okresów ochronnych ryb, limitów połowowych oraz powszechnie obowiązujących przepisów prawa skutkuje natychmiastowym odebraniem zezwolenia na wędkowanie, a także może skutkować poniesieniem przez naruszającego odpowiedzialności karnej lub cywilnej.",

  bass1:
    "1. Jezioro udostępnione jest do wędkowania w okresie od dnia 1 kwietnia do dnia 30 grudnia, przez całą dobę. W pozostałym okresie wędkowanie jest zabronione.",
  bass2:
    "2. Doba karpiowa liczona jest od godziny 14.00 do godziny 12.00 dnia następnego. W ostatni dzień zasiadki do godziny 13.00 należy opuścić zajmowane stanowisko.",
  bass3:
    "3. Przed rozpoczęciem połowu należy zameldować swoje przybycie u właściciela lub opiekuna łowiska oraz uregulować płatność za pozwolenie na wędkowanie.  ",
  bass4:
    "4. Uprawnienia do połowu ryb ma wyłącznie osoba, która nabyła pozwolenie na wędkowanie  ",
  bass5:
    "5. Dzieci i młodzież do lat 15-stu mogą przebywać na terenie łowiska wyłącznie pod opieką osób pełnoletnich.  ",
  bass6:
    "6. W ramach jednego zezwolenia na wędkowanie dopuszcza się łowienie na maksymalnie dwie wędki.  ",
  bass7:
    "7. Na każdym stanowisku musi znajdować się mata karpiowa, podbierak oraz odkażacz do ran tzw. clinik.",
  bass8: "8. Wymagania dotyczące mat karpiowych:",
  _bass9: "I. minimalny rozmiar maty jaką można stosować to 70 x 120cm,",
  _bass10: "II. mata powinna posiadać gruby materac,",
  _bass11:
    "III. niedozwolone jest stosowanie małych, cienkich mat „karpiowych”, które tylko z nazwy nadają się do poprawnego i bezpiecznego wyczepiania i wypuszczania ryb.",
  bass12:
    "9. Obowiązkowe jest posiadanie i używanie podbieraka, maty karpiowej oraz preparatu dezynfekującego rany ryb po ukłuciach haczyka. Złowione ryby należy wypiąć z haka na macie karpiowej lub w podbieraku w wodzie. Ze złowionymi rybami należy obchodzić się w sposób bardzo delikatny, dbając o ich zdrowie i bezpieczeństwo, należy polewać je wodą. Złowione ryby można zważyć i wykonać zdjęcia bez zbędnego przedłużania tej czynności.  ",
  bass13: "10. Zabrania się stosowania plecionki jako linki głównej.",
  bass14:
    "11. Każda ryba przed wypuszczeniem, po wyjęciu haka musi zostać zdezynfekowana.",
  bass15: "12. ZABRANIA SIĘ ROZPALANIA OGNISK!",
  bass16:
    "13. Nie wolno znaczyć łowiska przedmiotami innymi niż przeznaczone do tego markery.  Możliwe jest stosowanie jedynie markerów „tyczkowych”, jednocześnie zabrania się stosowania markerów typu „H”.",
  bass17:
    "14. Dopuszcza się możliwość rozbijania namiotów w obrębie stanowiska, jak również zanęcania, sondowania, stawiania markerów (wskazanych w pkt 13), wywożenia zestawów, podbierania ryb przy użyciu łódek – pontonów oraz za pomocą metod zdalnie sterowanych (do połowy odległości akwenu i możliwie w linii prostej), przy czym wszystkie te czynności nie mogą utrudniać wędkowania wędkarzom przebywającym na innych stanowiskach.",
  bass18:
    "15. Zabrania się nęcenia łowiska ziarnem, surowym i gotowanym:  kukurydzą, grochem, orzechem tygrysim, łubinem, etc.",
  bass19:
    "16. Dopuszcza się jedynie nęcenie gotowanymi ziarnami konopi oraz miękką, „puszkową” kukurydzą. Dobowy limit użycia zanęty wynosi 3kg.",
  bass20:
    "17. Po skończonym łowieniu zabrania się wyrzucania do wody reszty niezużytej zanęty.",
  bass21:
    "18. Każdy wędkujący zobowiązany jest do poddania się kontroli stanowiska, sprzętu i samochodu, przez właściciela jeziora, opiekuna jeziora, Policję, Straż Leśną, Państwową Straż Rybacką oraz osoby wymienione w pkt 27.",
  bass22:
    "19. W przypadku kradzieży ryby wędkujący zobowiązany jest zwrócić koszt w wysokości 100% wartości skradzionej ryby oraz zostaje na niego nałożony zakaz wstępu na łowisko",
  bass23:
    "20. W przypadku korzystania na terenie łowiska ze sprzętu kłusowniczego, oprócz obowiązku zwrotu kosztów o którym mowa w pkt 19, osoba kłusująca zobowiązana będzie do zapłaty kary w wysokości 1000 zł.  ",
  bass24:
    "21. Wszelkie sprawy nie ujęte w tym regulaminie należy konsultować z właścicielem lub opiekunem łowiska.",
  bass25:
    "22. W przypadku stwierdzenia jakichkolwiek naruszeń niniejszego regulaminu, a w szczególności: zaśmiecania i dewastowania otoczenia, głośnego i niekulturalnego zachowania oraz przeszkadzania innym osobom korzystającym z łowiska, właściciel lub opiekun łowiska uprawniony jest do natychmiastowego usunięcia łowiącego z zajmowanego stanowiska.  ",
  bass26:
    "23. Wędkarz zobowiązany jest wysprzątać swoje stanowisko wędkarskie przed i po zakończeniu wędkowania. Wszelkie nieczystości wędkarz zobowiązany jest zabrać ze sobą.",
  bass27:
    "24. Właściciel łowiska oraz opiekun łowiska nie ponoszą odpowiedzialności za wypadki, kradzieże i wszelkie zdarzenia losowe, powstałe na terenie łowiska, a także za rzeczy pozostawione na terenie łowiska przez osoby wkraczające na jego teren.",
  bass28:
    "25. Rozpoczęcie połowu jest równoznaczne z akceptacją obowiązującego regulaminu oraz cennika.",
  bass29:
    "26. Członkowie Pomorskiego Stowarzyszenia Karpiarzy posiadają rabaty cenowe na połów ryb oraz przywileje na jeziorze Strupino (niniejsze postanowienie obowiązuje od 01.05.2023 r.)",
  bass30: "27. Osobami upoważnionymi przez właściciela jeziora do kontroli są:",
  _bass31: "I. Tadeusz Kapicki;  ",
  _bass32: "II. Tomasz Siarczyńki;",
  _bass33: "III. Dawid Siarczyński;",
  _bass34: "IV. Marek Brylowski;",
  _bass35: "V. Danuta Brylowska.",
  _bass36: "VI. Mariusz Kutella",

  $rodo1: "Informacja dotycząca przetwarzania danych osobowych",
  rodo2:
    "Szanowni Państwo, Wypełniając obowiązek informacyjny, o którym mowa w art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), Dz. Urz. UE L 119/1 z dn. 04.05.2016 r. (dalej „RODO”), informuję, iż:",
  _rodo3:
    "1. Administratorem Państwa danych osobowych jest „GRANT” sp. z o.o., KRS: 0000313357, NIP: 5862224450, email:kontakt @strupino.pl, tel. 604-443-204 (dalej jako: Spółka).",
  _rodo4:
    "2. Państwa dane osobowe podane w zezwoleniu na wędkowanie przetwarzane będą w celach i w oparciu o podstawy prawne jak niżej",
  __rodo5:
    "I. dane przetwarzane będą w celu wydania zezwolenia na wędkowanie na podstawie wyrażonej przez Panią/Pana zgody (art. 6 ust. 1 lit. a RODO), w celu wykonania umowy, której jest Pani/Pan stroną lub do podjęcia działań na Pani/Pana żądanie przed zawarciem umowy (art. 6 ust. 1 lit. b RODO),",
  __rodo6:
    "II. dane osobowe będą przetwarzane w celu wypełnienia ciążących na Administratorze obowiązków prawnych wynikających z przepisów podatkowych i przepisów o rachunkowości (art. 6 ust. 1 lit. c RODO),",
  __rodo7:
    "III. dane osobowe przetwarzane są w celach analiz własnych administratora oraz w celu zapobiegania oszustwom, zapewnieniu bezpieczeństwa bądź dochodzenia roszczeń; wówczas podstawą przetwarzania danych osobowych jest uzasadniony interes prawny realizowany przez administratora lub stronę trzecią oparty na art. 6 ust. 1 lit. f RODO, ",
  __rodo8:
    "IV. dane osobowe przetwarzane są w celu rozpatrywania reklamacji dotyczących usług, podstawą przetwarzania danych osobowych jest obowiązek prawny ciążący na administratorze polegający na rozpatrywaniu reklamacji w ramach świadczonych usług, jak i niezbędność podjęcia przez administratora działań naprawczych w zakresie reklamowanych usług – przed zawarciem umowy o świadczenie tych usług; podstawą prawną przetwarzania danych osobowych w tym przypadku będzie art. 6 ust. 1 lit. b i c RODO,",
  _rodo9:
    "3.Odbiorcą Pani/Pana danych osobowych mogą być jedynie podmioty upoważnione na podstawie przepisów prawa oraz podmioty, które przetwarzają Pani/Pana dane osobowe na podstawie zawartej z Administratorem umowy powierzenia przetwarzania danych osobowych tj. podmioty współpracujące w zakresie dostarczania lub utrzymania systemów informatycznych, przedsiębiorstwom księgowo-kadrowym współpracującym z administratorem oraz przedsiębiorstwom kurierskim i pocztowym, prawnikom współpracującym z administratorem, pracownikom zatrudnionym przez Administratora (w tym osobom świadczącym usługi na podstawie umów cywilno-prawnych),",
  _rodo10:
    "4. Pani/Pana dane osobowe przetwarzane będą maksymalnie przez okres przedawnienia ewentualnych roszczeń wynikających z umów zawartych z Administratorem lub do czasu wygaśnięcia obowiązku przechowywania danych przez okres wymagany przepisami prawa podatkowego lub z zakresu księgowości.",
  _rodo11:
    "5. Pani/Pana dane nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.",
  _rodo12:
    "6. W każdym czasie uprawnieni są Państwo do dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz macie Państwo prawo do przenoszenia danych osobowych. Uprawnienia te mogą Państwo realizować korespondencyjnie oraz za pomocą środków komunikacji elektronicznej (mail).",
  _rodo13:
    "7. W każdym czasie uprawnieni są Państwo do wniesienia sprzeciwu wobec przetwarzania danych w oparciu o uzasadniony interes prawny administratora lub strony trzeciej. Uprawnienie to mogą Państwo realizować korespondencyjnie oraz za pomocą środków komunikacji elektronicznej (mail).",
  _rodo14:
    "8. Jeżeli uważają Państwo, że przetwarzanie danych osobowych przez Administratora narusza przepisy prawa, uprawnieni są Państwo do wniesienia skargi do organu nadzorczego Prezesa Urzędu Ochrony Danych Osobowych, adres: ul. Stawki 2, 00-193 Warszawa.",
  _rodo15:
    "9. Podanie przez Państwa danych osobowych jest wymogiem umownym/warunkiem zawarcia umowy i  jest niezbędne dla realizacji celów, o których mowa w pkt 2, a konsekwencją niepodania danych osobowych będzie niemożność realizacji tych celów. ",
  _rodo16:
    "10. Przetwarzanie Państwa danych osobowych nie będzie związane z tym, iż będziecie Państwo podlegać decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu i wywołuje wobec Państwa skutki prawne lub w podobny sposób istotnie na Państwa wpływa.",
  $rodo17:
    "Informacja dotycząca przetwarzania danych osobowych – monitoring wizyjny",
  rodo18:
    "Szanowni Państwo Wypełniając obowiązek informacyjny, o którym mowa w art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), Dz. Urz. UE L 119/1 z dn. 04.05.2016 r. (dalej „RODO”), informuję, iż:",
  _rodo19:
    "1. Administratorem Państwa danych osobowych jest „GRANT” sp. z o.o., KRS: 0000313357, NIP: 5862224450, email: kontakt @strupino.pl, tel. 604-443-204 (dalej jako: Spółka).",
  _rodo20:
    "2. Państwa dane osobowe w postaci wizerunku zarejestrowanego przez monitoring przetwarzane będą w celu zapewnienia porządku oraz bezpieczeństwa mienia i osób przebywających na terenie prywatnym Jeziora Strupino, a także w celu ochrony przeciwpożarowej i przeciwpowodziowej na podstawie art. 6 ust 1 lit.  RODO.",
  _rodo21:
    "3. W związku z określonym wyżej celem przetwarzania danych stosowanie monitoringu jest konieczne i uzasadnione.",
  _rodo22:
    "4. Obszar monitorowany obejmuje linię brzegową i jezioro tj. teren prywatny należący do Spółki. ",
  _rodo23:
    "5. W celu zapewnienia porządku oraz bezpieczeństwa osób przebywających na terenie prywatnym Jeziora Strupino, a także w celu zapewnienia ochrony przeciwpożarowej i przeciwpowodziowej przez Administratora, przebywanie na terenie objętym monitoringiem, spowoduje automatyczne pozyskanie danych osobowych przez Administratora. Administrator dla celów dowodowych zabezpiecza zdarzenia zarejestrowane przez system monitoringu wizyjnego, które między innymi zagrażają bezpieczeństwu i porządkowi publicznemu, niszczeniu i kradzieży mienia  na wniosek osób trzecich i na wniosek organów prowadzących postępowania.",
  _rodo24:
    "6. Każdorazowe zabezpieczenie zdarzeń zarejestrowanych przez monitoring wizyjny odbywa się na pisemny wniosek dostarczony na adres Spółki. Zabezpieczone dane z monitoringu wizyjnego są udostępniane na podstawie odrębnych przepisów.",
  _rodo25:
    "7. Pani/Pana dane mogą zostać przekazane podmiotom lub organom uprawnionym na podstawie przepisów prawa, w szczególności firmom świadczącym usługi obsługi księgowej Spółki, firmom świadczącym obsługę prawną oraz pracownikom Spółki.",
  _rodo26:
    "8. Pani/Pana dane nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.",
  _rodo27:
    "9. Pani/Pana dane osobowe będą zarejestrowane w formie elektronicznej w postaci obrazu z monitoringu wizyjnego oraz przechowywane przez okres 30 dni od dnia nagrania, a po upływie tego czasu zarejestrowany materiał zostanie trwale nadpisany przez nowe dane. W przypadku, gdy nagranie jest lub może być dowodem w postępowaniu prowadzonym na podstawie obowiązującego prawa przechowywane będzie przez okres niezbędny dla prawidłowej realizacji tych regulacji. ",
  _rodo28:
    "10. Pani/Pana dane nie będą przetwarzane w sposób zautomatyzowany, w tym nie będą podlegać profilowaniu.",
  _rodo29:
    "11. W każdym czasie uprawnieni są Państwo do dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz macie Państwo prawo do przenoszenia danych osobowych. Uprawnienia te mogą Państwo realizować korespondencyjnie oraz za pomocą środków komunikacji elektronicznej (mail).",
  _rodo30:
    "12. W każdym czasie uprawnieni są Państwo do wniesienia sprzeciwu wobec przetwarzania danych w oparciu o uzasadniony interes prawny administratora lub strony trzeciej. Uprawnienie to mogą Państwo realizować korespondencyjnie oraz za pomocą środków komunikacji elektronicznej (mail).",
  _rodo31:
    "13. Jeżeli uważają Państwo, że przetwarzanie danych osobowych przez Administratora narusza przepisy prawa, uprawnieni są Państwo do wniesienia skargi do organu nadzorczego Prezesa Urzędu Ochrony Danych Osobowych, adres: ul. Stawki 2, 00-193 Warszawa.",

  //news1
  title1: "FEEDER DEATHMATCH 2023",
  paragraph1:
    "Tym razem chcemy Was zaprosić na trzecią edycję Feeder Deathmatch 2023. 3 łowiska, 3 etapy, 1 zwycięzca. W pierwszym spotkaniu na Jezioro Strupino będzie brało udział 50 zapaleńców feedera klasycznego, którzy w dwóch sektorach stoczą bój o to, aby przejść do kolejnego etapu. Szczęście uśmiechnie się do 24 z nich... W kolejnym etapie, który odbędzie się na Łowisko Gniewino. NO KILL wyłonimy 12 finalistów, którzy zmierzą się w finale na malowniczym Łowisku Trzebiatki. Zapisy 799 959 720. ",

  //news2
  title2: "WIOSENNY PUCHAR  STALOMAX",
  paragraph2:
    "Jak widzicie, nie pozwolimy abyście nudzili się w weekendy. Proponujemy wam kolejną porcję rywalizacji Tym razem wraz z Stalomax, chcemy was zaprosić na Wiosenny Puchar STALOMAX, na jedną z piękniejszych wód w naszm rejonie- Jezioro Strupino. Zapis ruszają wraz z publikacją posta. Możecie to zrobić telefonicznie - 799959720 lub poprzez messenger'a.",

  //news3
  title3: "INTERNATIONAL CARP CHAMPIONS LEAGUE",
  paragraph3:
    "Pozostały już tylko nieco ponad dwa miesiące do startu naszych wyjątkowych zawodów, w których główna wygrana to 250 000 PLN, a pula nagród to aż 550 000 PLN brutto. Eliminacje na jeziorze strupinie odbędą sie 1-4.06.2023r. Zapraszamy!",

  //news4
  title4: "TARGI RYBOMANIA",
  paragraph4:
    "Kolejna Rybomania za nami! O samych targach nie będziemy się rozpisywać... Pozostawimy je ocenie każdego odwiedzającego i wystawcy z osobna.. Niemniej jednak, WIELKIE dzięki dla tych z którymi udało nam się zamienić kilka konstruktywnych słów, usłyszeć o tym czego jeszcze byście chcieli na stronie (będziemy sprawdzać możliwości ) i za wiele pozytywnych opinii. Nie będziemy wymieniać każdego z WAS z osobna, bo za dużo tego by było :) ",

  //news5
  title5: "KARP - CIEKAWOSTKI",
  paragraph5:
    " Karp jest to słodkowodna ryba należąca do rodziny karpiowatych. Jest najstarszą hodowaną przez człowieka rybą. Popularny był już w czasach starożytnego Rzymu. Pierwsze zapiski o hodowli karpia w Japonii pochodzą z 300 roku p.n.e.. Największy złowiony karp ważył 51,2 kg. Miał 112,64 cm długości. W Stanach Zjednoczonych i Australii karp uznawany jest za szkodnika. Wpisany został na listę stu najgorszych gatunków inwazyjnych.  Rocznie na świecie obławia się ponad 200 tysięcy ton karpia. Cechą charakterystyczną hodowanych karpi jest garbaty grzbiet.Mięso karpia jest zdrowsze od łososia. Ma dobrze przyswajalne kwasy Omega 3 i Omega 6. ",

  //news6
  title6: "KARPIOWY PUCHAR",
  paragraph6: "Pierwsza edycja innowacyjnej imprezy dla karpiarzy z całej Polski już 17-20.08.2023 r. na naszej wodzie. Pula nagród to imponujące 350 tysięcy złotych. Po więcej informacji zapraszamy na strone <a href='https://karpiowypuchar.pl' target='_blank'>karpiowypuchar.pl</a>",

  //news7
  title7: "",
  paragraph7: "",

  //news8
  title8: "",
  paragraph8: "",

  //news9
  title9: "",
  paragraph9: "",

  //kontakt
  warder: "Opiekun łowiska",
  owner: "Właściciel łowiska",
  emailInvalid: "Adres e-mail jest niepoprawny!",
  topicInvalid: "Temat wiadomości musi zostać podany!",
  messageInvalid: "Treść wiadomości musi zostać podana!",

  //index
  opis: "Zapraszamy do rezerwacji stanowisk wędkarskich na jeziorze strupino. Zapewniamy wspaniałe karpie, liny, szczupaki, jesiotry, sieje, płocie, węgorze, jazie i inne, a także urokliwe jezioro, dojazd w okolicę stanowisk i rezerwacje on-line na stronie",
  RezerwujStanowisko: "Dowiedz się więcej",
  opis1: "O JEZIORZE",
  opis12:
    "Strupino to śródleśne jezioro rynnowe na Równinie Charzykowskiej w gminie Kościerzyna, znajdujące się w północno-wschodnim malowniczym krańcu Wdzydzkiego Parku Krajobrazowego. Jezioro znajduje się na turystycznym Szlaku Kamiennych Kręgów. Powierzchnia całkowita jeziora wynosi: 50,67ha.",
  opis2: "NATURA",
  opis22:
    "Malownicze widoki, natura i spokój to rzeczy, które znajdziesz nad naszym jeziorem. Jezioro Strupino znajduje się na terenie NATURA 2000. Możemy również poszczycić sie czystą wodą, najczystszą na terenie Kaszub.",
  opis3: "ZARYBIANIE",
  opis32:
    "Od momentu przejęcia jeziora prowadzimy akcje intensywnego zarybiania. Jesienią 2021 roku jezioro zostało zarybione: Karpiem, Szczupakiem oraz rybą biała (płotki,liny)",
  kon: "ZAREZERWUJ STANOWISKO",
  kup: "REZERWUJ",

  subtitle:
    "Dokonaj rezerwacji już teraz! Zrób to bezpośrednio przez naszą strone, czyli w najszybszy możliwy sposób!",
  Title: "AKTUALNOŚCI",
  Subtitle1:
    "Bądź na bieżąco z Jeziorem Strupino! Zobacz już teraz najnowsze aktualności i informacje związane z jeziorem Strupino!",
  AKTUALNOSCI: "AKTUALNOŚCI",

  // rezerewacje

  stanowiska: "STANOWISKA",
  station1: "Stanowisko 1",
  station2: "Stanowisko 2",
  station3: "Stanowisko 3",

  station4: "Stanowisko 4",

  station5: "Stanowisko 5",

  station6: "Stanowisko 6",

  station7: "Stanowisko 7",

  station8: "Stanowisko 8",

  station9: "Stanowisko 9",

  station10: "Stanowisko 10",

  station11: "Stanowisko 11",

  station12: "Stanowisko 12",

  station13: "Stanowisko 13",

  station14: "Stanowisko 14",

  station15: "Stanowisko 15",

  station16: "Stanowisko 16",

  station17: "Stanowisko 17",

  station18: "Stanowisko 18",

  station19: "Stanowisko 19",

  station20: "Stanowisko 20",

  station21: "Stanowisko 21",

  station22: "Stanowisko 22",

  station23: "Stanowisko 23",

  station24: "Stanowisko 24",

  station25: "Stanowisko 25",

  station26: "Stanowisko 26",

  station27: "Stanowisko 27",

  station28: "Stanowisko 28",

  station29: "Stanowisko 29",

  station30: "Stanowisko 30",

  station31: "Stanowisko 31",

  station32: "Stanowisko 32",

  station33: "Stanowisko 33",

  station34: "Stanowisko 34",

  station35: "Stanowisko 35",

  station36: "Stanowisko 36",

  station37: "Stanowisko 37",

  station38: "Stanowisko 38",

  station39: "Stanowisko 39",

  station40: "Stanowisko 40",

  station41: "Stanowisko 41",

  station42: "Stanowisko 42",

  station43: "Stanowisko 43",

  station44: "Stanowisko 44",

  station45: "Stanowisko 45",

  station46: "Stanowisko 46",

  station47: "Stanowisko 47",

  station48: "Stanowisko 48",

  typeOfLicence: "Rodzaj licencji",
  dailyLicence2: "Licencja Dniowa - 2 wędki",
  dailyLicence3: "Licencja Dniowa - 3 wędki",
  bassLicence2: "Licencja Karpiowa - 2 wędki",
  bassLicence4: "Licencja Karpiowa - 3 wędki",
  spiningLicence: "Licencja Spinngowa",
  station: "Stanowiska",
  startDate: "Data początkowa",
  endDate: "Data końcowa",
  book: "Zarezerwuj",
  phone: "Numer telefonu",
  email: "Adres email",
  plate: "Numer rejestracyjny",
  totalCost: "Koszt całkowity",
  dates: "Termin",
  workInProgresHeader: "Prace w trakcie",
  workInProgresText: "Zapraszamy wkrótce",
};

const enTranslation = {
  address: "Address:",
  telephone: "Telephone number:",
  strupinoLake: "Strupino Lake",
  galery: "Galery",
  events: "News",
  stations: "Stations",
  termsOfUse: "Terms of use",
  pricing: "Pricing",
  booking: "Booking",
  contact: "Contact",
  prices: "Prices",
  pricesDailyIntro: "Daylight License         ",
  pricesDailyRod2: "max 2 rods - 40zł",
  pricesDailyRod3: "max 3 rods - 50zł",
  pricesDobaIntro: "Licencja Karpiowa (dobowa)",
  pricesDobaRod2: "max 2 rods - 60zł",
  pricesDobaRod3: "max 3 rods - 70zł",
  pricesSpinningIntro: "Spinning License (from the shore)",
  pricesSpinningRod2: "from dawn to dusk",
  pricesSpinningRod3: "40zł",
  pricesMakeReservation: "Make reservation",
  pricesTerms: "Terms",
  pricesLine1: "Day starts counting at 2 P.M. until 1:30 P.M. next day",
  pricesLine2: "You can buy license from Tadeusz Kapicki, tel. 693 823 261",

  contact: "Contact",

  introText:
    "Inhalations are an important part of treating a horse with respiratory disease. Your veterinarian may prescribe an inhaled steroid, which works better than steroids in many horses, intravenously or intramuscularly.",
  introSubtitle: "Look at our products and inhale your horse",
  introButton: "Shop now",
  shopHeader: "Our products",
  shopMaskCardIntro: "Strupino",
  shopMaskCardParagraph:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  shopMaskCardButton: "Buy",
  learnMore: "Learn more",
  homeCardTitle: "Inhalation for your horse",
  homeCardText:
    "Inhalations are an important part of treating a horse with respiratory disease. Your veterinarian may prescribe an inhaled steroid, which works better than steroids in many horses, intravenously or intramuscularly.",
  customizeInfoHeader: "Maska skrtojonja dla twojego konia",
  customizeInfoText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  customizeInfoSubtitle: "Maska jakiej chcesz",
  customizeCallToAction: "Dostosuj swoja maske teraz",
  customizeButton: "Stwórz maskę",
  whyToInhale: "Dlaczego inhalować",
  whyToInhaleHeader1: "For health",
  whyToInhaleHeader2: "For strength",
  whyToInhaleHeader3: "For speed",
  whyToInhaleSubstring1:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  whyToInhaleSubstring2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  whyToInhaleSubstring3:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  chooseSize: "Wybierz rozmiar",
  addText: "Add text",
  topic: "Temat",
  message: "Wiadomość",
  sendMessage: "Wyślij wiadomość",
  def1: "1. Provision of the regulations from April 24, 2022.",
  def2: "2. Potential changes to these regulations will be published on the website www.strupino.pl and shall enter into force on the day of their publication.",
  def3: "3.Whenever these Regulations refer to:",
  _def4:
    "I. owner of the fishery - it means a person authorized to represent and conduct the affairs of GRANT sp. z o.o.;",
  _def5:
    "II. keeper of the fishery - it means a person appointed by the owner of the fishery to take care of the fishery, authorized to issue fishing permits and carry out inspections in this regard;",
  _def6:
    "III. fishing permit - it means a personal document (daily/daily license) issued against payment by the owner of the fishery or the keeper of the fishery, entitling to fish in Lake Strupino, for the time specified in the permit;",
  _def7:
    "IV. position - it means a separate place, a pier, located on Lake Strupino, from which fishing takes place.",
  def8: "4. The entire area of the lake is private property.",
  def9: "5. Anglers in the waters. of Lake Strupino are obliged to comply with the provisions of the Act of 18 April 1985 on inland fishing (i.e. Journal of Laws of 2019, item 2168, as amended), ordinances to the above-mentioned of the Act, generally applicable regulations and the provisions of these regulations.",
  def10:
    "6. Fishing on Lake Strupino is available only after reading the regulations and paying for fishing regulations.",
  def11:
    "7. A fishing permit must be purchased from the owner or keeper of the fishery or at selected points of sale. At the time of purchase, the owner or keeper of the fishery assigns the buyer a position where he will be entitled to fish. It is forbidden to independently change the assigned position.",
  def12:
    "8. For safety reasons, the position must be used with extreme caution, in particular:",
  _def13: " I. minors must be accompanied by an adult;",
  _def14: "II. At night it is recommended to use life jackets.",
  def15:
    "9. One fishing person is entitled to one fishing permit in Lake Strupino (does not apply to accompanying children under 12 years of age).",
  def16:
    "10. In order for the fishing permit to be valid, it is necessary to have an identity card or other document with a photo to confirm the identity of the person.",
  def17:
    "11. It is allowed to fish using the ground method, float method (max. 2 rods), or spinning method (max. 1 rod).",
  def18:
    "12. In case of catching a Carp or a Pike, regardless of the size of the caught fish - you should release the fish back into the water (no kill rule).",
  def19:
    "13. Persons violating the regulations, fishing limits, and non-compliance with the protection periods for fish will be expelled from Lake Strupino, without reimbursement of fees and without the possibility of re-entering the lake.",
  def20:
    "14. At any time, the owner of the fishery, the Fisheries Guard, the Police, the Forest Guard and authorized persons (listed in point 20 of these regulations) may inspect the fishing permit. Purchasing a fishing permit is tantamount to consenting to such an inspection.",
  def21:
    "15. In the case of refusal by the angler to present a fishing permit together with an identity card or other document with a photo for inspection to confirm his identity to the persons listed in point 20 of the regulations, a state authority authorized to do so will be called.",
  def22:
    "16. The owner of the fishery has the right to close the reservoir for the duration of fishing competitions or other events on the fishery. All information in this regard will be made available on the website at www.strupino.pl.",
  def23:
    "17. In Lake Strupino, it is forbidden to lure fisheries with raw grain, including: corn, peas, tiger nuts, lupins, etc., as well as raw and spoiled grain. The guardians of the fishery have the right to inspect the groundbaits.",
  def24:
    "18. After fishing, it is forbidden to throw the rest of the unused groundbait into the water. The daily limit for the use of groundbait is 3kg.",
  def25:
    "19. In order to ensure order and safety of property and people staying on the private area of Lake Strupino, as well as for fire and flood protection, GRANT sp. z o.o. conducts video surveillance. The monitored area covers the shoreline and the lake, i.e. private land belonging to the company. More information on the processing of personal data in the form of an image recorded by monitoring can be found on the website www.strupino.pl.",
  def26: "20. Additionally, on the area of Lake Strupino it is forbidden to:",
  _def27:
    " I. Start fishing without prior purchase of a lake fishing permit, unless the person interested in purchasing a permit receives permission from the fishery supervisor after prior agreement with him on the method and date of payment for the fishing permit;",
  _def28: "II. Littering the fishery area, shoreline and reservoir;",
  _def29:
    "III. Destroying green areas, including cutting and breaking trees and shrubs;",
  _def30: "IV. Lighting fires;",
  _def31: "V. Loud behavior.",
  def32:
    "21. The persons authorized by the owner of Lake Strupino to inspect are:",
  _def33: "I.Tadeusz Kapicki;",
  _def34: "II. Tomasz Siarczyński",
  _def35: "III. Dawid Siarczyński;",
  _def36: "IV. Marek Brylowski;",
  _def37: "V. Danuta Brylowska.",
  _def38: "VI. Mariusz Kutella",
  def39:
    "22. Any matters not covered by these regulations should be consulted with the guardian or owner of the fishery.",
  _def40:
    "I.Contact to the owner of the fishery: Tadeusz Kapicki, phone no. 693 823 261;",
  _def41:
    "II. Contact to the owner of the fishery: Tomasz Siarczyński, phone no. 604 443 204.",
  def42:
    "23. In disputable situations, the final decision always rests with the owner or guardian of the fishery.",
  def43:
    "24. Diving, fishing with a crossbow and fishing from a boat are prohibited on the lake.",
  def44:
    "25. Fishing from a boat (spinning) is possible only after prior arrangement of the fishing date with the owner or guardian of the fishery. At the same time, each person on the boat is obliged not to use it under the influence of alcohol or other intoxicants, not to make the boat available to minors without the supervision of a guardian and to wear a life jacket.",
  def45:
    "26. The owner of the fishery and the guardian of the fishery are not responsible for accidents, thefts and any random events occurring in the Strupino fishery, as well as for items left in the fishery by persons entering its area.",
  def46: "27. Catch limits - fish sizes and seasons:",
  _def47:
    "I. The daily catch limit on Lake Strupino is a total of 5 kg of caught fish (does not apply to bream, silver bream, grass carp, silver crucian carp and catfish).",
  _def48:
    "II. It is forbidden to substitute fish previously intended for collection. The protective dimensions for individual fish are:",
  __def49: "a. roach - protective size up to 15 cm;",
  __def50: "b. bream (without protective dimension);",
  __def51: "c. silver bream and crucian carp (unlimited);",
  __def52: "d. common crucian carp (golden) - protective size up to 15 cm;",
  __def53: "e. carp - we release it back into the water (no kill rule);",
  __def54: "f. perch - protective size up to 18 cm;",
  __def55: "g. lin - protective dimension up to 25 cm;",
  __def56:
    "h. whitefish - protective size up to 35 cm (protection period from October 15 to December 31) limit of 3 pcs.;  ",
  __def57: "i. pike - we release back into the water (no kill rule);",
  __def58:
    "j. catfish - protective size up to 70 cm (protection period January 1 - May 31);",
  __def59:
    "k. eel - limit 2 pcs. protection size up to 50 cm (protection period from December 1 to March 31).",
  def60:
    "28. It is only allowed to bait with boiled hemp seeds and soft, 'canned' corn. The daily limit for the use of groundbait is 3kg.",
  def61:
    "29. After fishing, it is forbidden to throw the rest of the unused groundbait into the water.",
  def62: "30. Swimming is not allowed in the fishery.",
  def63:
    "31. The area of the facility is not a swimming area within the meaning of the Water Law Act of 20 July 2017 (i.e. Journal of Laws of 2021, item 2233, as amended), therefore bathing in the lake is at your own risk and responsibility.",
  def64:
    "32. Failure to comply with the above regulations, dimensions and closed seasons of fish, catch limits and generally applicable laws results in the immediate withdrawal of the fishing permit, and may also result in criminal or civil liability by the violator.",

  bass1:
    "1. The lake is available for fishing in the period from April 1 to December 30, 24 hours a day. In the rest of the period, fishing is prohibited.",
  bass2:
    "2. The carp day is counted from 14.00 to 12.00 the next day. On the last day of the session you must leave your position by 13.00.",
  bass3:
    "3. Before fishing, you must register your arrival with the owner or keeper of the fishery and settle the payment for the fishing license.",
  bass4:
    "4. Only the person who has purchased the fishing license is allowed to fish",
  bass5:
    "5. Children and teenagers under the age of 15 may stay in the fishery only under the care of adults. ",
  bass6: "6. A maximum of two rods are allowed per fishing permit.",
  bass7:
    "7. Every position must have a carp mat, landing net and disinfectant for wounds, the so-called clinic.",
  bass8: "8. Requirements for carp mats:",
  _bass9: "I. the minimum size of the mat that can be used is 70 x 120cm,",
  _bass10: "II. mat should have a thick mattress,",
  _bass11:
    "III. it is not allowed to use small, thin carp mats, which only by name are suitable for correct and safe unhooking and releasing fish.",
  bass12:
    "9. It is mandatory to have and use a landing net, a carp mat and a preparation that disinfects fish wounds after hook bites. Caught fish should be unhooked from the hook on the carp mat or in the landing net in the water. Caught fish should be handled in a very delicate way, taking care of their health and safety, you should pour water on them. Caught fish can be weighed and photos taken without undue prolongation of this activity. ",
  bass13: "10. It is forbidden to use braid as main line.",
  bass14:
    "11. Each fish must be disinfected before being released after removing the hook.",
  bass15: "12. DO NOT LIGHT A FIRE!",
  bass16:
    "13. It is not allowed to mark the fishery with objects other than the markers intended for this purpose. It is possible to use only 'pole' markers, while using 'H' type markers is prohibited.",
  bass17:
    "14. It is allowed to pitch tents within the site, as well as baiting, sounding, placing markers (indicated in point 13), transporting sets, collecting fish using boats - pontoons and using remotely controlled methods (up to half the distance of the water area and possibly in a straight line), provided that all these activities cannot hinder fishing for anglers staying at other positions.",
  bass18:
    "15. It is forbidden to lure the fishery with grain, raw and cooked: corn, peas, tigernut, lupine, etc.",
  bass19:
    "16. It is only allowed to bait with boiled hemp seeds and soft, 'canned' corn. The daily limit for the use of groundbait is 3kg.",
  bass20:
    "17. After fishing, it is forbidden to throw the rest of the unused groundbait into the water.",
  bass21:
    "18. Each angler is obliged to undergo an inspection of the position, equipment and car by the owner of the lake, the guardian of the lake, the Police, the Forest Guard, the State Fisheries Guard and the persons listed in point 27.",
  bass22:
    "19. In case of theft of fish, the angler is obliged to return the cost of 100% of the value of the stolen fish and he is banned from entering the fishery",
  bass23:
    "20. If poaching equipment is used in the fishery, in addition to the obligation to reimburse the costs referred to in point 19, the poacher will be required to pay a fine of PLN 1,000. ",
  bass24:
    "21. Any matters not included in these regulations should be consulted with the owner or guardian of the fishery.",
  bass25:
    "22. If any violations of these regulations are found, in particular: littering and devastation of the surroundings, loud and impolite behavior and disturbing other people using the fishery, the owner or guardian of the fishery is entitled to immediately remove the fisherman from his position. ",
  bass26:
    "23. The angler is obliged to clean his fishing position before and after fishing. The angler is obliged to take any dirt with him.",
  bass27:
    "24. The owner of the fishery and the keeper of the fishery are not responsible for accidents, theft and any random events occurring in the fishery, as well as for things left in the fishery by persons entering its area.",
  bass28:
    "25. Starting fishing is tantamount to accepting the applicable regulations and price list.",
  bass29:
    "26. Members of the Pomeranian Association of Carp anglers have price discounts for fishing and privileges on Lake Strupino (this provision is valid from 01/05/2023)",
  bass30: "27. The persons authorized by the owner of the lake to inspect are:",
  _bass31: "I. Tadeusz Kapicki;  ",
  _bass32: "II. Tomasz Siarczyńki;",
  _bass33: "III. Dawid Siarczyński;",
  _bass34: "IV. Marek Brylowski;",
  _bass35: "V. Danuta Brylowska.",
  _bass36: "VI. Mariusz Kutella",

  $rodo1: "Information on the processing of personal data",
  rodo2:
    "Ladies and Gentlemen, Fulfilling the information obligation referred to in Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and the repeal of Directive 95/46/EC (General Data Protection Regulation), Official Journal EU L 119/1 of May 4, 2016 (hereinafter referred to as GDPR), I inform that:",
  _rodo3:
    "1. The administrator of your personal data is GRANT sp. z o.o., KRS: 0000313357, NIP: 5862224450, email: kontakt@strupino.pl, tel. 604-443-204 (hereinafter: the Company).",
  _rodo4:
    "2. Your personal data provided in the fishing permit will be processed for the purposes and based on the legal grounds as below",
  __rodo5:
    "I. the data will be processed in order to issue a fishing permit based on your consent (Article 6(1)(a) of the GDPR), to perform a contract to which you are a party or to take action at your request before concluding a contract (Article 6(1)(b) of the GDPR),",
  __rodo6:
    "II. personal data will be processed in order to fulfill the Administrator's legal obligations resulting from tax and accounting regulations (Article 6(1)(c) of the GDPR),",
  __rodo7:
    "III. personal data is processed for the purposes of the administrator's own analyzes and in order to prevent fraud, ensure security or pursue claims; then the basis for the processing of personal data is a legitimate legal interest pursued by the administrator or a third party based on Article 6(1)(f) GDPR, ",
  __rodo8:
    "IV. personal data is processed in order to consider complaints regarding services, the basis for the processing of personal data is the legal obligation imposed on the administrator to consider complaints as part of the services provided, as well as the necessity for the administrator to take corrective actions in the field of the advertised services - before concluding a contract for provision of these services; the legal basis for the processing of personal data in this case will be Article 6(1)(b) and (c) of the GDPR,",
  _rodo9:
    "3. The recipient of your personal data may only be entities authorized under the law and entities that process your personal data on the basis of a personal data processing agreement concluded with the Administrator, i.e. entities cooperating in the provision or maintenance of IT systems, accounting and human resources companies cooperating with the administrator and courier and postal companies, lawyers cooperating with the administrator, employees employed by the Administrator (including persons providing services under civil law contracts),",
  _rodo10:
    "4. Your personal data will be processed for the maximum period of limitation of any claims arising from contracts concluded with the Administrator or until the expiry of the obligation to store data for the period required by tax or accounting law.",
  _rodo11:
    "5. Your data will not be transferred to a third country or an international organization.",
  _rodo12:
    "6. At any time, you are entitled to access your personal data, rectify them, delete or limit processing, and you have the right to transfer personal data. You can exercise these rights by correspondence and by means of electronic communication (e-mail).",
  _rodo13:
    "7. At any time, you are entitled to object to the processing of data based on the legitimate legal interest of the administrator or a third party. You can exercise this right by correspondence and by means of electronic communication (email).",
  _rodo14:
    "8. If you believe that the processing of personal data by the Administrator violates the law, you are entitled to lodge a complaint with the supervisory body of the President of the Office for Personal Data Protection, address: ul. Stawki 2, 00-193 Warsaw.",
  _rodo15:
    "9. Providing your personal data is a contractual requirement/condition for concluding a contract and is necessary to achieve the goals referred to in point 2, and the consequence of not providing personal data will be the inability to achieve these goals. ",
  _rodo16:
    "10. The processing of your personal data will not be related to the fact that the state's conduct is subject to a decision that is solely subject to automated demonstration, including profiling, and legal effects on you or in the manner of conduct that significantly affects you.",
  $rodo17:
    "Information on the processing of personal data - video surveillance",
  rodo18:
    "Dear Ladies and Gentlemen, Fulfilling the information obligation referred to in Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation), Official Journal EU L 119/1 of May 4, 2016 (hereinafter referred to as GDPR), I inform you that:",
  _rodo19:
    "1. The administrator of your personal data is GRANT sp. z o.o., KRS: 0000313357, NIP: 5862224450, email: kontakt@strupino.pl, tel. 604-443-204 (hereinafter: the Company).",

  _rodo20:
    "2. Your personal data in the form of an image recorded by monitoring will be processed in order to ensure order and safety of property and persons staying on the private area of ​​Lake Strupino, as well as for fire and flood protection pursuant to Article 6(1)(a) of the GDPR.",
  _rodo21:
    "3. In connection with the purpose of data processing specified above, the use of monitoring is necessary and justified.",
  _rodo22:
    "4. The monitored area includes the shoreline and the lake, i.e. private land belonging to the Company. ",
  _rodo23:
    "5. In order to ensure order and safety of people staying in the private area of ​​Lake Strupino, as well as to ensure fire and flood protection by the Administrator, staying in the area covered by monitoring will automatically obtain personal data by the Administrator. The Administrator secures events recorded for evidence purposes by a video monitoring system, which, among others, threaten public safety and order, destroy and steal property at the request of third parties and at the request of the authorities conducting the proceedings.",
  _rodo24:
    "6. Each time, the security of events recorded by video monitoring takes place on a written request delivered to the Company's address. Secured data from video monitoring are made available on the basis of separate regulations.",
  _rodo25:
    "7. Your data may be transferred to entities or bodies authorized under the law, in particular to companies providing accounting services to the Company, companies providing legal services and employees of the Company.",
  _rodo26:
    "8. Your data will not be transferred to a third country or an international organization.",
  _rodo27:
    "9. Your personal data will be registered in electronic form in the form of an image from video surveillance and stored for a period of 30 days from the date of recording, and after that time the recorded material will be permanently overwritten by new data. If the recording is or may be evidence in proceedings conducted on the basis of applicable law will be stored for the period necessary for the proper implementation of these regulations. ",
  _rodo28:
    "10. Your data will not be processed in an automated manner, including profiling.",
  _rodo29:
    "11. At any time, you are entitled to access your personal data, rectify them, delete or limit processing, and you have the right to transfer personal data. You can exercise these rights by correspondence and by means of electronic communication (e-mail).",
  _rodo30:
    "12. At any time, you are entitled to object to the processing of data based on the legitimate legal interest of the administrator or a third party. You can exercise this right by correspondence and by means of electronic communication (email).",
  _rodo31:
    "13. If you believe that the processing of personal data by the Administrator violates the law, you are entitled to lodge a complaint with the supervisory body of the President of the Office for Personal Data Protection, address: ul. Stawki 2, 00-193 Warsaw.",

  //news1
  title1: "FEEDER DEATHMATCH 2023",
  paragraph1:
    "Tym razem chcemy Was zaprosić na trzecią edycję Feeder Deathmatch 2023. 3 łowiska, 3 etapy, 1 zwycięzca. W pierwszym spotkaniu na Jezioro Strupino będzie brało udział 50 zapaleńców feedera klasycznego, którzy w dwóch sektorach stoczą bój o to, aby przejść do kolejnego etapu. Szczęście uśmiechnie się do 24 z nich... W kolejnym etapie, który odbędzie się na Łowisko Gniewino. NO KILL wyłonimy 12 finalistów, którzy zmierzą się w finale na malowniczym Łowisku Trzebiatki. Zapisy 799 959 720. ",

  //news2
  title2: "WIOSENNY PUCHAR  STALOMAX",
  paragraph2:
    "Jak widzicie, nie pozwolimy abyście nudzili się w weekendy. Proponujemy wam kolejną porcję rywalizacji Tym razem wraz z Stalomax, chcemy was zaprosić na Wiosenny Puchar STALOMAX, na jedną z piękniejszych wód w naszm rejonie- Jezioro Strupino. Zapis ruszają wraz z publikacją posta. Możecie to zrobić telefonicznie - 799959720 lub poprzez messenger'a.",

  //news3
  title3: "INTERNATIONAL CARP CHAMPIONS LEAGUE",
  paragraph3: "Pozostały już tylko nieco ponad dwa miesiące do startu naszych wyjątkowych zawodów, w których główna wygrana to 250 000 PLN, a pula nagród to aż 550 000 PLN brutto. Eliminacje na jeziorze strupinie odbędą sie 1-4.06.2023r. Zapraszamy!",

    //news4
  title4: "TARGI RYBOMANIA",
  paragraph4:
    "Kolejna Rybomania za nami! O samych targach nie będziemy się rozpisywać... Pozostawimy je ocenie każdego odwiedzającego i wystawcy z osobna.. Niemniej jednak, WIELKIE dzięki dla tych z którymi udało nam się zamienić kilka konstruktywnych słów, usłyszeć o tym czego jeszcze byście chcieli na stronie (będziemy sprawdzać możliwości ) i za wiele pozytywnych opinii. Nie będziemy wymieniać każdego z WAS z osobna, bo za dużo tego by było :) ",

  //news5
  title5: "KARP - CIEKAWOSTKI",
  paragraph5:
    " Karp jest to słodkowodna ryba należąca do rodziny karpiowatych. Jest najstarszą hodowaną przez człowieka rybą. Popularny był już w czasach starożytnego Rzymu. Pierwsze zapiski o hodowli karpia w Japonii pochodzą z 300 roku p.n.e.. Największy złowiony karp ważył 51,2 kg. Miał 112,64 cm długości. W Stanach Zjednoczonych i Australii karp uznawany jest za szkodnika. Wpisany został na listę stu najgorszych gatunków inwazyjnych.  Rocznie na świecie obławia się ponad 200 tysięcy ton karpia. Cechą charakterystyczną hodowanych karpi jest garbaty grzbiet.Mięso karpia jest zdrowsze od łososia. Ma dobrze przyswajalne kwasy Omega 3 i Omega 6. ",

  //news6
  title6: "KARPIOWY PUCHAR",
  paragraph6: "Pierwsza edycja innowacyjnej imprezy dla karpiarzy z całej Polski już 17-20.08.2023 r. na naszej wodzie. Pula nagród to imponujące 350 tysięcy złotych. Po więcej informacji zapraszamy na strone <a href='https://karpiowypuchar.pl' target='_blank'>karpiowypuchar.pl</a>",

  //news7
  title7: "",
  paragraph7: "",

  //news8
  title8: "",
  paragraph8: "",

  //news9
  title9: "",
  paragraph9: "",
  //kontakt
  warder: "Keeper of the Lake",
  owner: "Owner of the Fishery",
  emailInvalid: "The email address is invalid!",
  topicInvalid: "The subject of the message must be specified!",
  messageInvalid: "The content of the message must be entered!",

  //index
  opis: "We invite you to book fishing spots on Lake Strupino. We provide great carp, a charming lake, access in the area and online reservations on the website",
  RezerwujStanowisko: "Book a position",
  opis1: "About the lake",
  opis12:
    "Strupino is a ribbon lake in the middle of the forest on the Charzykowska Plain in the Kościerzyna commune, located in the north-eastern picturesque end of the Wdzydze Landscape Park. The lake is located on the tourist Route of Stone Circles. The total area of ​​the lake is: 50.67 ha.",
  opis2: "NATURE",
  opis22:
    "Scenic views, nature and peace are the things you will find at our lake. Lake Strupino is located in the NATURA 2000 area. We can also be proud of clean water, the cleanest in Kashubia.",
  opis3: "stocking",
  opis32:
    "Since taking over the lake, we have been conducting intensive restocking. In autumn 2021, the lake was stocked with: Carp, Pike and white fish (roaches, ropes)",
  kon: "CONTACT US",
  kup: "CONTACT",

  //Rezerwacje
  stanowsika: "POSITIONS",
  station1: "Position 1",
  station2: "Position 2",
  station3: "Position 3",

  station4: "Position 4",

  station5: "Position 5",

  station6: "Position 6",

  station7: "Position 7",

  station8: "Position 8",

  station9: "Position 9",

  station10: "Position 10",

  station11: "Position 11",

  station12: "Position 12",

  station13: "Position 13",

  station14: "Position 14",

  station15: "Position 15",

  station16: "Position 16",

  station17: "Position 17",

  station18: "Position 18",

  station19: "Position 19",

  station20: "Position 20",

  station21: "Position 21",

  station22: "Position 22",

  station23: "Position 23",

  station24: "Position 24",

  station25: "Position 25",

  station26: "Position 26",

  station27: "Position 27",

  station28: "Position 28",

  station29: "Position 29",

  station30: "Position 30",

  station31: "Position 31",

  station32: "Position 32",

  station33: "Position 33",

  station34: "Position 34",

  station35: "Position 35",

  station36: "Position 36",

  station37: "Position 37",

  station38: "Position 38",

  station39: "Position 39",

  station40: "Position 40",

  station41: "Position 41",

  station42: "Position 42",

  station43: "Position 43",

  station44: "Position 44",

  station45: "Position 45",

  station46: "Position 46",

  station47: "Position 47",

  station48: "Position 48",

  typeOfLicence: "Type of license",

  dailyLicence2: "Day License - 2 rods",
  dailyLicence3: "Day License - 3 rods",
  bassLicence2: "Carp license - 2 rods",
  bassLicence4: "Carp license - 3 rods",
  spiningLicence: "Spinning License",

  station: "Positions",
  startDate: "Starting date",
  endDate: "End date",
  book: "Make a reservation",
  phone: "Phone",
  email: "E-mail",
  plate: "Registration number",
  totalCost: "Total Cost",
  dates: "Data",
  workInProgresHeader: "Work in progress",
  workInProgresText: "Come back soon",
};

const resources = {
  en: {
    translation: enTranslation,
  },
  pl: {
    translation: plTranslation,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "pl",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
