const terms = {
  Regulamin: {
    1: "def1",
    2: "def2",
    3: "def3",
    4: "_def4",
    5: "_def5",
    6: "_def6",
    7: "_def7",
    8: "def8",
    9: "def9",
    10: "def10",
    11: "def11",
    12: "def12",
    13: "_def13",
    14: "_def14",
    15: "def15",
    16: "def16",
    17: "def17",
    18: "def18",
    19: "def19",
    20: "def20",
    21: "def21",
    22: "def22",
    23: "def23",
    24: "def24",
    25: "def25",
    26: "def26",
    27: "_def27",
    28: "_def28",
    29: "_def29",
    30: "_def30",
    31: "_def31",
    32: "def32",
    33: "_def33",
    34: "_def34",
    35: "_def35",
    36: "_def36",
    37: "_def37",
    38: "_def38",
    39: "def39",
    40: "_def40",
    41: "_def41",
    42: "def42",
    43: "def43",
    44: "def44",
    45: "def45",
    46: "def46",
    47: "_def47",
    48: "_def48",
    49: "__def49",
    50: "__def50",
    51: "__def51",
    52: "__def52",
    53: "__def53",
    54: "__def54",
    55: "__def55",
    56: "__def56",
    57: "__def57",
    58: "__def58",
    59: "__def59",
    60: "def60",
    61: "def61",
    62: "def62",
  },
  "Regulamin dla łowców karpi": {
    1: "bass1",
    2: "bass2",
    3: "bass3",
    4: "bass4",
    5: "bass5",
    6: "bass6",
    7: "bass7",
    8: "bass8",
    9: "_bass9",
    10: "_bass10",
    11: "_bass11",
    12: "bass12",
    13: "bass13",
    14: "bass14",
    15: "bass15",
    16: "bass16",
    17: "bass17",
    18: "bass18",
    19: "bass19",
    20: "bass20",
    21: "bass21",
    22: "bass22",
    23: "bass23",
    24: "bass24",
    25: "bass25",
    26: "bass26",
    27: "bass27",
    28: "bass28",
    29: "bass29",
    30: "bass30",
    31: "_bass31",
    32: "_bass32",
    33: "_bass33",
    34: "_bass34",
    35: "_bass35",
    36: "_bass36",
  },
  Rodo: {

    1: "$rodo1",
    2: "rodo2",
    3: "_rodo3",
    4: "_rodo4",
    5: "__rodo5",
    6: "__rodo6",
    7: "__rodo7",
    8: "__rodo8", 
    9: "_rodo9",
    10: "_rodo10",
    11: "_rodo11",
    12: "_rodo12",
    13: "_rodo13",
    14: "_rodo14",
    15: "_rodo15",
    16: "_rodo16",
    17: "$rodo17",
    18: "rodo18",
    19: "_rodo19",
    20: "_rodo20",
    21: "_rodo21",
    22: "_rodo22",
    23: "_rodo23",
    24: "_rodo24",
    25: "_rodo25",
    26: "_rodo26",
    27: "_rodo27",
    28: "_rodo28",
    29: "_rodo29",
    30: "_rodo30",
    31: "_rodo31",

  },
};

export default terms;
