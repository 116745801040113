import palette from "../../consts/palette";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import backgroundIntro from "../../assets/background_intro.webp";
import dojazdPhoto from "../../assets/DOJAZD.webp";
import zarybianie from "../../assets/zarybianie.webp";
import natura from "../../assets/natura.webp";
import ojeziorze from "../../assets/ojeziorze.webp";
import feed from "../../assets/feed.webp";

import backgroundIntroTel from "../../assets/background_intro_tel.webp";
import zarybianieTel from "../../assets/zarybianie_tel.webp";
import naturaTel from "../../assets/natura_tel.webp";
import ojeziorzeTel from "../../assets/ojeziorze_tel.webp";
import feedTel from "../../assets/feed_tel.webp";
import routes from "../../consts/routes";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:400px)");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "88vh",
          "@media (max-width: 4500px)": {
            backgroundImage: `url(${backgroundIntro})`,
          },
          "@media (max-width: 400px)": {
            backgroundImage: `url(${backgroundIntroTel})`,
          },

          backgroundSize: { xs: "none", md: "100% 100%" },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          <Grid
            item
            md={6}
            mt="5%"
            ml={3}
            sx={{
              borderRadius: "25px",
              backgroundColor: "rgb(256,256,256,0.2)",
              p: 3,
            }}
          >
            <b>
              <Typography
                sx={{ typography: { xs: "h4", md: "h2" } }}
                align="right"
                mx={2}
              >
                {t("strupinoLake")}
              </Typography>
            </b>
            <Typography
              sx={{
                typography: { xs: "subtitle1", md: "h5" },
                ml: "20%",
                mb: "3vh",
              }}
              align="right"
              mx={2}
            >
              {t("opis")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div />
              <Button
                variant="contained"
                size="large"
                sx={{ backgroundColor: palette.darkBlue, mx: 2, my: 1 }}
              >
                {t("RezerwujStanowisko")}
              </Button>
            </Box>
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </Box>
      <Box my="5vh">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} mb={1}>
            <Paper
              sx={{
                mx: 2,
                pt: 2,
                minHeight: "100%",
              }}
            >
              <Typography variant="h4" align="center">
                {t("opis1")}
              </Typography>
              <Divider color="black" sx={{ my: 2 }} />
              <Typography
                variant="subtitle1"
                align="center"
                minHeight="170px"
                mx={3}
              >
                {t("opis12")}
              </Typography>

              <img
                src={matches ? ojeziorze : ojeziorzeTel}
                width="96%"
                height={300}
                style={{ margin: "2%" }}
                alt="lake sunset"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} mb={1}>
            <Paper sx={{ mx: 2, pt: 2, minHeight: "100%" }}>
              <Typography variant="h4" align="center">
                {t("opis2")}
              </Typography>
              <Divider color="black" sx={{ my: 2 }} />
              <Typography
                variant="subtitle1"
                align="center"
                minHeight="170px"
                mx={3}
              >
                {t("opis22")}
              </Typography>
              <img
                src={matches ? natura : naturaTel}
                width="96%"
                height={300}
                style={{ margin: "2%" }}
                alt="lake sunset 2"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} mb={1}>
            <Paper sx={{ mx: 2, pt: 2, minHeight: "100%" }}>
              <Typography variant="h4" align="center">
                {t("opis3")}
              </Typography>
              <Divider color="black" sx={{ my: 2 }} />
              <Typography
                variant="subtitle1"
                align="center"
                minHeight="170px"
                mx={3}
              >
                {t("opis32")}
              </Typography>
              <img
                src={matches ? zarybianie : zarybianieTel}
                width="96%"
                height={300}
                style={{ margin: "2%" }}
                alt="lake sunset 3"
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* <Box my="5vh">
        <Paper sx={{ m: 5 }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                height="100%"
                width="100%"
                maxWidth="90vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="h4" align="center">
                  {t("kon")}
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {t("subtitle")}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    alignSelf: "center",
                    backgroundColor: palette.darkBlue,
                    mx: 2,
                    my: 1,
                  }}
                >
                  {t("kup")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={5}>
                <img
                  src={backgroundIntro}
                  width="80%"
                  height="80%"
                  style={{ borderRadius: "50%" }}
                  alt="lake sunset 4"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box> */}

      {/* dojazd */}
      <Box>
        <Typography variant="h3" align="center" mx={2}>
          {t("Dojazd")}
        </Typography>
        <Divider
          sx={{
            mb: "2vh",
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Link
          href="https://goo.gl/maps/UaLmu4QfFjKLjFdJA"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={dojazdPhoto}
            style={{ maxWidth: "70%", marginLeft: "15%" }}
            alt="asdasd"
          ></img>
          <Box textAlign="center" sx={{ widht: "100vw" }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                my: 2,
                backgroundColor: palette.darkBlue,
              }}
              href="https://goo.gl/maps/UaLmu4QfFjKLjFdJA"
              target="_blank"
            >
              Dojazd
            </Button>
          </Box>
        </Link>
      </Box>
      <Grid container m={5}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: "10vw",
            }}
          >
            <Typography variant="h4" align="center">
              {t("Title")}
            </Typography>
            <Divider color="black" />
            <Typography variant="subtitle1" align="center" mx="10%" my="2%">
              {t("Subtitle1")}
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: "60%",
                alignSelf: "center",
                backgroundColor: palette.darkBlue,
                mx: 2,
                my: 1,
              }}
              onClick={() => navigate(`/${routes.events}`)}
            >
              {t("AKTUALNOSCI")}
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "60%",
                alignSelf: "center",
                backgroundColor: palette.darkBlue,
                mx: 2,
                my: 1,
              }}
              onClick={() => navigate(`/${routes.stations}`)}
            >
              {t("Podgląd Stanowisk")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={matches ? feed : feedTel}
            alt="aktualnosci"
            style={{ maxWidth: "90%", margin: "5%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;

const styleMap = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  ml: "30%",
  mr: "1%",
};
