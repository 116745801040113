import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// import spinning from "../assets/spinning.jpeg";
import Typography from "@mui/material/Typography";
// import CardImage from "../../assets/background_intro.png";
import { useTranslation } from "react-i18next";
import palette from "../../consts/palette";
import { Link } from "react-router-dom";

interface LicenseComponentInterface {
  content: string;
  photo: string;
}

export default function LicenseComponent({
  content,
  photo,
}: LicenseComponentInterface) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: { xs: "90%", md: "80%" },
        backgroundColor: palette.darkBlue,
        margin: "auto",
        color: "white",
        marginBottom: "50px",
        mt: 3,
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={photo}
        alt="Licencja conent"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align="center">
          {t("prices" + content + "Intro")}
        </Typography>
        <Typography variant="body1" align="center">
          {t("prices" + content + "Rod2")}
        </Typography>
        <Typography variant="body1" align="center">
          {t("prices" + content + "Rod3")}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button sx={{ backgroundColor: palette.darkBlue }}>
          <Link
            to="/rezerwacje"
            style={{ color: "white", alignSelf: "center" }}
          >
            {t("pricesMakeReservation")}
          </Link>
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: palette.darkBlue,
            minWidth: "130px",
          }}
        >
          <Link
            to="/termsOfUse"
            style={{ color: "white", alignSelf: "center" }}
          >
            {t("pricesTerms")}
          </Link>
        </Button>
      </CardActions>
    </Card>
  );
}
