import routes from "../../consts/routes";
import palette from "../../consts/palette";
import config from "../../consts/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReservationInterface from "../../consts/interfaces/ReservationInterface";
import EngineeringIcon from "@mui/icons-material/Engineering";

function Booking() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stations: string[] = [
    "station1",
    "station2",
    "station3",
    "station4",
    "station5",
    "station6",
    "station7",
    "station8",
    "station9",
    "station10",
    "station11",
    "station12",
    "station13",
    "station14",
    "station15",
    "station16",
    "station17",
    "station18",
    "station19",
    "station20",
    "station21",
    "station22",
    "station23",
    "station24",
    "station25",
    "station26",
    "station27",
    "station28",
    "station29",
    "station30",
    "station31",
    "station32",
    "station33",
    "station34",
    "station35",
    "station36",
    "station37",
    "station38",
    "station39",
    "station40",
    "station41",
    "station42",
    "station43",
    "station44",
    "station45",
    "station46",
    "station47",
    "station48",
  ];

  const pricing = {
    dailyLicence2: 40,
    dailyLicence3: 50,
    bassLicence2: 60,
    bassLicence4: 70,
    spiningLicence: 40,
  };

  const [selectedTypeOfPrice, setSelectedTypeOfPrice] = useState<string>("");
  const [selectedStation, setSelectedStation] = useState<string>("");

  const todayDate = dayjs();
  const [startDate, setStartDate] = useState<Dayjs | undefined | null>(
    todayDate
  );
  const [endDate, setEndDate] = useState<Dayjs | undefined | null>(startDate);

  const [reservedDatesForStation, setReservedDatesForStation] = useState<
    Array<string>
  >([]);

  const handleSelectedStationChange = (event: SelectChangeEvent) => {
    setSelectedStation(event.target.value as string);
  };

  const handleSelectedTypeOfPrice = (event: SelectChangeEvent) => {
    setSelectedTypeOfPrice(event.target.value as string);
  };

  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [plate, setPlate] = useState<string>("");

  const [price, setPrice] = useState<number>(0);

  const [formStateErrors, setFormStateErrors] = useState({
    typeOfLicence: "licenceInvalid",
    station: "station",
    email: "emailInvalid",
    phone: "phoneInvalid",
    plate: "plateInvalid",
  });

  useEffect(() => {
    setPrice(
      selectedTypeOfPrice && startDate && endDate
        ? selectedTypeOfPrice === "bassLicence2" ||
          selectedTypeOfPrice === "bassLicence4"
          ? endDate?.diff(startDate, "day")!
          : endDate?.add(1, "day").diff(startDate, "day")! *
            pricing[selectedTypeOfPrice as keyof typeof pricing]
        : 0
    );
  }, [endDate, startDate, selectedTypeOfPrice]);

  useEffect(() => {
    if (
      selectedTypeOfPrice === "bassLicence2" ||
      selectedTypeOfPrice === "bassLicence4"
    ) {
      setEndDate(startDate?.add(1, "day")!);
    }
  }, [selectedTypeOfPrice]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const getDatesBetween = (start: string, end: string) => {
        var dateArray = [];
        var currentDate = dayjs(start);
        var endDate = dayjs(end);
        while (currentDate <= endDate) {
          dateArray.push(currentDate.toString());
          currentDate = currentDate.add(1, "day");
        }
        return dateArray;
      };

      const getDatesForStation = async (stationId: number) => {
        const stationDates = await axios.get(
          config.apiIp + "/reservation/getDatesPerStation",
          { params: { station_id: stationId } }
        );
        const disabledDates: any[] = [];

        stationDates.data.forEach(
          (element: { start_date: string; end_date: string }) => {
            const datesBetween = getDatesBetween(
              element.start_date,
              element.end_date
            );
            disabledDates.push(...datesBetween);
          }
        );

        return disabledDates;
      };

      const dates = await getDatesForStation(
        Number(selectedStation.split("tation")[1])
      );

      setReservedDatesForStation(dates);
    };
    asyncUseEffect();
  }, [selectedStation]);

  const shouldDisableStationDate = (date: Dayjs) => {
    return reservedDatesForStation.includes(date.toString());
  };

  const emailValidator = (value: string) => {
    setEmail(value);
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      setFormStateErrors({ ...formStateErrors, email: "emailInvalid" });
    } else {
      setFormStateErrors({ ...formStateErrors, email: "" });
    }
  };

  const onBookStation = async () => {
    const reservation: ReservationInterface = {
      station_id: Number(selectedStation.split("tation")[1]),
      price: price,
      payed: false,
      phone: phone,
      email: email,
      plate: plate,
      expired: false,
      start_date: startDate!.format("YYYY-MM-DD"),
      end_date: endDate!.format("YYYY-MM-DD"),
    };
    const respose = await axios.post(
      config.apiIp + "/reservation/createReservation",
      reservation
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        my: 3,
      }}
    >
      <Stack marginLeft="auto" marginRight="auto">
        <Paper sx={{ minWidth: {md:700, xs:200}, minHeight: 400, m: 5 }}>
          <Grid container p={2}>
            <Grid item xs={12} md={6} height="50%"  sx={{marginBottom:{ xs:"30%"}}}>
              <Typography variant="h6" align="center" sx={{marginTop:{md:"40%", xs:"10%"}}}>
                {t("workInProgresHeader")}
              </Typography>
              <Divider />
              <Typography variant="h5" align="center">
                {t("workInProgresText")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              height="50%"
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ marginTop: { xs: "15%", md: "50%" } }}>
                <EngineeringIcon
                  style={{ transform: "scale(12)", color: palette.lightBlue }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
        {/* <Typography
          sx={{ typography: { xs: "h4", md: "h2" } }}
          align="center"
          mt="2vh"
        >
          {t("booking")}
        </Typography>
        <Divider
          sx={{
            mb: "2vh",
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker"]}
            sx={{ display: "flex", justifyContent: "space-evenly", m: 3 }}
          >
            <DemoItem label={t("typeOfLicence")}>
              <Select
                fullWidth
                sx={{ minWidth: "240px", backgroundColor: "white" }}
                value={selectedTypeOfPrice}
                onChange={handleSelectedTypeOfPrice}
              >
                {Object.keys(pricing).map((item) => (
                  <MenuItem value={item} key={item}>
                    {t(item)}
                  </MenuItem>
                ))}
              </Select>
            </DemoItem>
            <DemoItem label={t("station")}>
              <Select
                fullWidth
                sx={{ minWidth: "240px", backgroundColor: "white" }}
                value={selectedStation}
                onChange={handleSelectedStationChange}
              >
                {stations.map((item) => (
                  <MenuItem value={item} key={item}>
                    {t(item)}
                  </MenuItem>
                ))}
              </Select>
            </DemoItem>
            <DemoItem label={t("startDate")}>
              <DatePicker
                defaultValue={startDate}
                minDate={todayDate!}
                sx={{ minWidth: "240px", backgroundColor: "white" }}
                disabled={selectedStation === ""}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                shouldDisableDate={shouldDisableStationDate}
              />
            </DemoItem>
            <DemoItem label={t("endDate")}>
              <DatePicker
                defaultValue={endDate}
                minDate={
                  selectedTypeOfPrice === "bassLicence2" ||
                  selectedTypeOfPrice === "bassLicence4"
                    ? startDate?.add(1, "day")!
                    : startDate!
                }
                sx={{ minWidth: "240px", backgroundColor: "white" }}
                disabled={selectedStation === ""}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                shouldDisableDate={shouldDisableStationDate}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <Paper sx={{ my: 5, mx: 3, minHeight: 400 }}>
          tu beda zdjecia stanowisk
        </Paper>
        <Typography variant="h4" ml={3}>
          {t("book")}
        </Typography>
        <Divider sx={{ mb: 3, mx: 1, backgroundColor: "black" }} />
        <Grid container sx={{ mx: 3 }}>
          <Grid
            item
            sx={{
              width: "50%",
            }}
            xs={12}
            md={6}
          >
            <TextField
              fullWidth
              sx={textFieldStyling}
              id="phone"
              label={t("phone")}
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            <TextField
              fullWidth
              sx={textFieldStyling}
              id="email"
              label={t("email")}
              value={email}
              onChange={(event) => emailValidator(event.target.value)}
            />
            <TextField
              fullWidth
              sx={textFieldStyling}
              id="plate"
              label={t("plate")}
              value={plate}
              onChange={(event) => setPlate(event.target.value)}
            />
          </Grid>

          <Grid item sx={{ width: "50%", p: 2 }} xs={12} md={6}>
            <Paper sx={{ mb: 3, px: 5, py: 2, mx: 2 }}>
              <Typography variant="h5" align="left" my={2}>
                {t("typeOfLicence")}: {t(selectedTypeOfPrice)}
              </Typography>
              <Typography variant="h5" align="left" my={2}>
                {t("station")} : {selectedStation}
              </Typography>
              <Typography variant="h5" align="left" my={2}>
                {t("dates")}: {startDate?.format("YYYY-MM-DD")} -{" "}
                {endDate?.format("YYYY-MM-DD")}
              </Typography>
              <Typography variant="h5" align="left" my={2}>
                {t("totalCost")}:{price} zł
              </Typography>
            </Paper>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={onBookStation}
              sx={{ mx: 5, width: "80%" }}
            >
              {t("book")}
            </Button>
          </Grid>
        </Grid> */}
      </Stack>
    </Box>
  );
}

export default Booking;

const textFieldStyling = {
  backgroundColor: "white",
  my: 2,
};
