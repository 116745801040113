import { Box, Grid, Typography } from "@mui/material";
import palette from "../../consts/palette";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../assets/logostrupino.webp";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

import routes from "../../consts/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function FooterComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      p={3}
      sx={{
        borderTop: `1px solid ${palette.green}`,
        backgroundColor: palette.green,
        minHeigth: "300px",
        p: 3,
        minHeight: "5vh",
      }}
    >
      <Grid container sx={{ color: palette.beige }}>
        <Grid xs={12} md={4} item>
          {/* TODO : WYSIZOWAC JAKOS TE IKONKI ( JEZIORO STRUPINO MOZE NIE BO FIXED SIZE MU DAMY) */}
          <Typography variant="subtitle2" align="center" sx={textStyling}>
            <img src={Logo} alt="strupino lake logo" />
          </Typography>
          <Typography variant="body1" align="center" sx={textStyling}>
            <YouTubeIcon />
          </Typography>
          <Typography variant="subtitle2" align="center" sx={textStyling}>
            <FacebookIcon />
          </Typography>
        </Grid>
        <Grid xs={12} md={4} item>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.galery}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("galery")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.events}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("events")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.stations}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("stations")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.termsOfUse}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("termsOfUse")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.pricing}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("pricing")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.booking}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("booking")}
          </Typography>
          <Typography
            display="block"
            variant="body1"
            component="a"
            onClick={() => navigate(`/${routes.contact}`)}
            align="center"
            sx={navigateButtonStyling}
          >
            {t("contact")}
          </Typography>
        </Grid>
        <Grid xs={12} md={4} item>
          <Typography variant="subtitle2" align="center" sx={textStyling}>
            Kontakt:
          </Typography>
          <Typography variant="subtitle2" align="center" sx={textStyling}>
            <CallIcon sx={{ mr: 2 }} />
            693 823 261
          </Typography>
          <Typography variant="subtitle2" align="center" sx={textStyling}>
            <EmailIcon sx={{ mr: 1 }} /> strupino.jezioro@gmail.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
export default FooterComponent;
// TODO TO TU DALEM FONT SIZE JAK COS ZEBY WIENKSZE BYLY ALE NIE SOM
const textStyling = {
  fontFamily: "monospace",
  fontWeight: 700,
  letterSpacing: ".1rem",
  fontSize: "1.3rem",
};
const navigateButtonStyling = {
  mr: 1,
  fontFamily: "monospace",
  fontWeight: 700,
  letterSpacing: ".1rem",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
};
