import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import routes from "../../consts/routes";
import mapa from "../assets/stations.webp";

interface MapComponentInterface {
  onStationClick: (id: number) => void;
}

//TODO zapytanie do api czy sa wolne zwraca liste jak jest na liscie to zielone jak nie to nie
function MapComponent({ onStationClick }: MapComponentInterface) {
  const free = false;
  // 12-17,20,21,22,23,24,25,26,27,28,29,30,31,32,34
  return (
    <Box alignSelf="center" width="100%" height="100%">
      <svg
        version="1.1"
        viewBox="0 0 1977 1013"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ alignSelf: "center", borderRadius: "50px" }}
      >
        <g>
          <image
            width="1977"
            height="1013"
            xlinkHref={mapa}
            preserveAspectRatio="none"
          />{" "}
          {/* <path
            d="m306.5 563.7a21.76 20.12 0 0 1-21.61 20.12 21.76 20.12 0 0 1-21.91-19.84 21.76 20.12 0 0 1 21.3-20.4 21.76 20.12 0 0 1 22.2 19.56"
            style={{
              fillOpacity: 0.5,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
              fill: free ? "green" : "red",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m292.1 516.4a20.9 20.22 0 0 1-20.75 20.22 20.9 20.22 0 0 1-21.04-19.94 20.9 20.22 0 0 1 20.46-20.5 20.9 20.22 0 0 1 21.33 19.66"
            style={{
              fillOpacity: 0.5,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
              fill: "green",
            }}
            onClick={() => onStationClick(2)}
          />
          <path
            d="m429.6 439.1a26.97 24.27 0 0 1-26.78 24.27 26.97 24.27 0 0 1-27.15-23.93 26.97 24.27 0 0 1 26.4-24.6 26.97 24.27 0 0 1 27.52 23.59"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(3)}
          />
          <path
            d="m561.3 420.9a21.35 20.45 0 0 1-21.2 20.45 21.35 20.45 0 0 1-21.49-20.16 21.35 20.45 0 0 1 20.9-20.73 21.35 20.45 0 0 1 21.79 19.87"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(4)}
          />
          <path
            d="m615.7 418.9a20.45 19.77 0 0 1-20.31 19.77 20.45 19.77 0 0 1-20.59-19.5 20.45 19.77 0 0 1 20.02-20.05 20.45 19.77 0 0 1 20.87 19.22"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(5)}
          />
          <path
            d="m673.2 427.9a22.47 21.12 0 0 1-22.31 21.12 22.47 21.12 0 0 1-22.63-20.83 22.47 21.12 0 0 1 22-21.41 22.47 21.12 0 0 1 22.93 20.53"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(6)}
          />
          <path
            d="m681.8 540.4a20.67 19.1 0 0 1-20.53 19.1 20.67 19.1 0 0 1-20.82-18.83 20.67 19.1 0 0 1 20.24-19.36 20.67 19.1 0 0 1 21.1 18.56"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(7)}
          />
          <path
            d="m700.7 597.7a22.02 22.02 0 0 1-21.87 22.02 22.02 22.02 0 0 1-22.17-21.71 22.02 22.02 0 0 1 21.56-22.32 22.02 22.02 0 0 1 22.47 21.4"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(8)}
          />
          <path
            d="m739.8 547.2a18.88 17.3 0 0 1-18.74 17.3 18.88 17.3 0 0 1-19.01-17.06 18.88 17.3 0 0 1 18.48-17.54 18.88 17.3 0 0 1 19.26 16.82"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(9)}
          />
          <path
            d="m807.6 500.4a23.59 25.84 0 0 1-23.43 25.84 23.59 25.84 0 0 1-23.76-25.48 23.59 25.84 0 0 1 23.1-26.2 23.59 25.84 0 0 1 24.08 25.12"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(10)}
          />
          <path
            d="m870.5 509.2a25.17 26.07 0 0 1-24.99 26.07 25.17 26.07 0 0 1-25.34-25.7 25.17 26.07 0 0 1 24.64-26.42 25.17 26.07 0 0 1 25.68 25.33"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(11)}
          />
          <path
            d="m1049 389.4a27.19 24.04 0 0 1-27 24.04 27.19 24.04 0 0 1-27.38-23.71 27.19 24.04 0 0 1 26.62-24.37 27.19 24.04 0 0 1 27.75 23.37"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1110 366.7a25.62 22.02 0 0 1-25.44 22.02 25.62 22.02 0 0 1-25.79-21.71 25.62 22.02 0 0 1 25.08-22.32 25.62 22.02 0 0 1 26.14 21.4"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1192 347.4a27.19 22.02 0 0 1-27 22.02 27.19 22.02 0 0 1-27.38-21.71 27.19 22.02 0 0 1 26.62-22.32 27.19 22.02 0 0 1 27.75 21.4"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1255 377.1a21.8 25.17 0 0 1-21.65 25.17 21.8 25.17 0 0 1-21.95-24.82 21.8 25.17 0 0 1 21.34-25.51 21.8 25.17 0 0 1 22.24 24.46"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1318 477.7a28.09 24.27 0 0 1-27.89 24.27 28.09 24.27 0 0 1-28.28-23.93 28.09 24.27 0 0 1 27.5-24.6 28.09 24.27 0 0 1 28.67 23.59"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1396 530.1a30.11 25.39 0 0 1-29.9 25.39 30.11 25.39 0 0 1-30.32-25.04 30.11 25.39 0 0 1 29.48-25.74 30.11 25.39 0 0 1 30.73 24.68"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1475 533.7a25.39 21.35 0 0 1-25.22 21.35 25.39 21.35 0 0 1-25.57-21.05 25.39 21.35 0 0 1 24.86-21.64 25.39 21.35 0 0 1 25.91 20.75"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(18)}
          />
          <path
            d="m1524 553.1a24.5 19.96 0 0 1-24.33 19.96 24.5 19.96 0 0 1-24.67-19.68 24.5 19.96 0 0 1 23.98-20.23 24.5 19.96 0 0 1 25 19.4"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(19)}
          />
          <path
            d="m1558 596.6a20.9 18.2 0 0 1-20.75 18.2 20.9 18.2 0 0 1-21.04-17.95 20.9 18.2 0 0 1 20.46-18.45 20.9 18.2 0 0 1 21.33 17.69"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1605 625.6a18.43 21.57 0 0 1-18.3 21.57 18.43 21.57 0 0 1-18.55-21.27 18.43 21.57 0 0 1 18.04-21.87 18.43 21.57 0 0 1 18.8 20.97"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1657 619.5a20.67 21.8 0 0 1-20.53 21.8 20.67 21.8 0 0 1-20.82-21.49 20.67 21.8 0 0 1 20.24-22.1 20.67 21.8 0 0 1 21.1 21.18"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1610 697.1a27.64 22.92 0 0 1-27.45 22.92 27.64 22.92 0 0 1-27.83-22.6 27.64 22.92 0 0 1 27.06-23.24 27.64 22.92 0 0 1 28.21 22.28"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1509 675.5a28.99 21.12 0 0 1-28.79 21.12 28.99 21.12 0 0 1-29.19-20.83 28.99 21.12 0 0 1 28.38-21.41 28.99 21.12 0 0 1 29.58 20.53"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1441 691.9a28.99 21.8 0 0 1-28.79 21.8 28.99 21.8 0 0 1-29.19-21.49 28.99 21.8 0 0 1 28.38-22.1 28.99 21.8 0 0 1 29.58 21.18"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1421 749a30.11 24.04 0 0 1-29.9 24.04 30.11 24.04 0 0 1-30.32-23.71 30.11 24.04 0 0 1 29.48-24.37 30.11 24.04 0 0 1 30.73 23.37"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1432 802.9a29.21 25.84 0 0 1-29.01 25.84 29.21 25.84 0 0 1-29.41-25.48 29.21 25.84 0 0 1 28.6-26.2 29.21 25.84 0 0 1 29.81 25.12"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1420 885.8a25.17 22.47 0 0 1-24.99 22.47 25.17 22.47 0 0 1-25.34-22.16 25.17 22.47 0 0 1 24.64-22.78 25.17 22.47 0 0 1 25.68 21.84"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1352 898.6a25.84 23.59 0 0 1-25.66 23.59 25.84 23.59 0 0 1-26.02-23.27 25.84 23.59 0 0 1 25.3-23.92 25.84 23.59 0 0 1 26.37 22.93"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1337 824.5a28.09 22.7 0 0 1-27.89 22.7 28.09 22.7 0 0 1-28.28-22.38 28.09 22.7 0 0 1 27.5-23.01 28.09 22.7 0 0 1 28.67 22.06"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1337 845.8a0.4494 0.8988 0 0 1-0.4463 0.8988 0.4494 0.8988 0 0 1-0.4525-0.8863 0.4494 0.8988 0 0 1 0.44-0.9112 0.4494 0.8988 0 0 1 0.4586 0.8736"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1296 754.4a28.54 24.94 0 0 1-28.34 24.94 28.54 24.94 0 0 1-28.73-24.59 28.54 24.94 0 0 1 27.94-25.29 28.54 24.94 0 0 1 29.12 24.24"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1314 683.3a28.54 21.8 0 0 1-28.34 21.8 28.54 21.8 0 0 1-28.73-21.49 28.54 21.8 0 0 1 27.94-22.1 28.54 21.8 0 0 1 29.12 21.18"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1297 611.9a29.66 22.25 0 0 1-29.46 22.25 29.66 22.25 0 0 1-29.87-21.94 29.66 22.25 0 0 1 29.04-22.55 29.66 22.25 0 0 1 30.27 21.62"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(33)}
          />
          <path
            d="m1214 609a26.07 22.02 0 0 1-25.88 22.02 26.07 22.02 0 0 1-26.25-21.71 26.07 22.02 0 0 1 25.52-22.32 26.07 22.02 0 0 1 26.6 21.4"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1132 613.2a26.29 25.39 0 0 1-26.11 25.39 26.29 25.39 0 0 1-26.47-25.04 26.29 25.39 0 0 1 25.74-25.74 26.29 25.39 0 0 1 26.83 24.68"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m1071 609.6a22.47 21.35 0 0 1-22.31 21.35 22.47 21.35 0 0 1-22.63-21.05 22.47 21.35 0 0 1 22-21.64 22.47 21.35 0 0 1 22.93 20.75"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(36)}
          />
          <path
            d="m1021 624a21.57 18.65 0 0 1-21.42 18.65 21.57 18.65 0 0 1-21.72-18.39 21.57 18.65 0 0 1 21.12-18.91 21.57 18.65 0 0 1 22.01 18.13"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m973.9 644.7a28.09 26.74 0 0 1-27.89 26.74 28.09 26.74 0 0 1-28.28-26.37 28.09 26.74 0 0 1 27.5-27.11 28.09 26.74 0 0 1 28.67 25.99"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m906.9 659.1a23.37 24.94 0 0 1-23.21 24.94 23.37 24.94 0 0 1-23.53-24.59 23.37 24.94 0 0 1 22.88-25.29 23.37 24.94 0 0 1 23.85 24.24"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(38)}
          />
          <path
            d="m840.9 680.7a26.97 23.15 0 0 1-26.78 23.14 26.97 23.15 0 0 1-27.15-22.82 26.97 23.15 0 0 1 26.4-23.46 26.97 23.15 0 0 1 27.52 22.5"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(39)}
          />
          <path
            d="m778.4 670.5a19.33 17.08 0 0 1-19.19 17.08 19.33 17.08 0 0 1-19.46-16.84 19.33 17.08 0 0 1 18.92-17.31 19.33 17.08 0 0 1 19.72 16.6"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(40)}
          />
          <path
            d="m740.6 702.4a23.15 20.22 0 0 1-22.98 20.22 23.15 20.22 0 0 1-23.3-19.94 23.15 20.22 0 0 1 22.66-20.5 23.15 20.22 0 0 1 23.62 19.66"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(41)}
          />
          <path
            d="m651.7 704.9a19.55 17.75 0 0 1-19.41 17.75 19.55 17.75 0 0 1-19.68-17.5 19.55 17.75 0 0 1 19.14-18 19.55 17.75 0 0 1 19.95 17.25"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m588.7 667.8a25.84 25.62 0 0 1-25.66 25.62 25.84 25.62 0 0 1-26.02-25.26 25.84 25.62 0 0 1 25.3-25.97 25.84 25.62 0 0 1 26.37 24.9"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(43)}
          />
          <path
            d="m569.9 604.9a26.29 19.33 0 0 1-26.11 19.32 26.29 19.33 0 0 1-26.47-19.06 26.29 19.33 0 0 1 25.74-19.59 26.29 19.33 0 0 1 26.83 18.78"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(1)}
          />
          <path
            d="m571.2 540a23.15 22.25 0 0 1-22.98 22.25 23.15 22.25 0 0 1-23.3-21.94 23.15 22.25 0 0 1 22.66-22.55 23.15 22.25 0 0 1 23.62 21.62"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(45)}
          />
          <path
            d="m511 559.1a21.57 20.67 0 0 1-21.42 20.67 21.57 20.67 0 0 1-21.72-20.38 21.57 20.67 0 0 1 21.12-20.96 21.57 20.67 0 0 1 22.01 20.09"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(46)}
          />
          <path
            d="m435 615a23.15 18.65 0 0 1-22.98 18.65 23.15 18.65 0 0 1-23.3-18.39 23.15 18.65 0 0 1 22.66-18.91 23.15 18.65 0 0 1 23.62 18.13"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(47)}
          />
          <path
            d="m382.5 613.7a26.97 24.49 0 0 1-26.78 24.49 26.97 24.49 0 0 1-27.15-24.15 26.97 24.49 0 0 1 26.4-24.83 26.97 24.49 0 0 1 27.52 23.81"
            style={{
              fillOpacity: 0,
              paintOrder: "stroke",
              strokeWidth: 0.5,
              stroke: "#000",
            }}
            onClick={() => onStationClick(48)}
          /> */}
        </g>
      </svg>
    </Box>
  );
}
export default MapComponent;
