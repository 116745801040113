import routes from "../../consts/routes";
import palette from "../../consts/palette";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { NewsCardInterface } from "../news/NewsCard";
import NewsCard from "../news/NewsCard";
import NewsPhoto1 from "../assets/news/1NEWS.webp";
import NewsPhoto2 from "../assets/news/2NEWS.webp";
import NewsPhoto3 from "../assets/news/3NEWS.webp";
import NewsPhoto4 from "../assets/news/4news.webp";
import NewsPhoto5 from "../assets/news/5news.webp";
import NewsPhoto6 from "../assets/news/6NEWS.webp";
import Carousel from "react-material-ui-carousel";

const carouselContent: Array<NewsCardInterface> = [
  {
    title: "title3",
    date: "1/03/2023",
    paragraph: "paragraph3",
    image: NewsPhoto3,
  },

  {
    title: "title1",
    date: "1/03/2023",
    paragraph: "paragraph1",
    image: NewsPhoto1,
  },
  {
    title: "title2",
    date: "20/02/2023",
    paragraph: "paragraph2",
    image: NewsPhoto2,
  },
];

const newsArray: Array<NewsCardInterface> = [
  {
    title: "title6",
    date: "20/03/2023",
    paragraph: "paragraph6",
    image: NewsPhoto6,
  },
  {
    title: "title1",
    date: "1/03/2023",
    paragraph: "paragraph1",
    image: NewsPhoto1,
  },
  {
    title: "title2",
    date: "20/02/2023",
    paragraph: "paragraph2",
    image: NewsPhoto2,
  },
  {
    title: "title3",
    date: "1/03/2023",
    paragraph: "paragraph3",
    image: NewsPhoto3,
  },
  {
    title: "title4",
    date: "06/03/2023",
    paragraph: "paragraph4",
    image: NewsPhoto4,
  },
  {
    title: "title5",
    date: "06/03/2023",
    paragraph: "paragraph5",
    image: NewsPhoto5,
  },
];

function News() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        minHeight: "100vh",
        mt: 3,
        ml: 5,
        mr: 5,
      }}
    >
      <Stack alignSelf="center">
        <Typography
          sx={{ typography: { xs: "h4", md: "h2" } }}
          align="center"
          mt="2vh"
        >
          {t("events")}
        </Typography>
        <Divider
          sx={{
            mb: "2vh",
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2vh",
            marginBottom: "2vh",
          }}
        />
        <Carousel>
          {carouselContent.map((item) => (
            <NewsCard
              key={item.title}
              title={t(item.title)}
              date={item.date}
              paragraph={t(item.paragraph)}
              image={item.image}
            />
          ))}
        </Carousel>
        <Box sx={{ m: 5 }}>
          {newsArray.map((item, index) => (
            <>
              <Divider sx={{ my: 5 }} />
              <NewsCard
                key={item.title}
                title={t(item.title)}
                date={item.date}
                paragraph={t(item.paragraph)}
                image={item.image}
              />
            </>
          ))}
        </Box>
      </Stack>
    </Box>
  );
}

export default News;
