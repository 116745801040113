import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import palette from "../../consts/palette";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Paper } from "@mui/material";
import LicenseComponent from "../common/LicenseComponent";
import spinning from "../assets/spinning1.webp";
import carp_day from "../assets/carpfishing.webp";
import daily from "../assets/dailylicense.webp";

function Prices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cards = ["daylight", "day", "spinning"];

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "5vh",
        flexDirection: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ width: { xs: "90%", md: "70%" }, mx: 1 }}>
        <Typography
          sx={{ typography: { xs: "h4", md: "h2" } }}
          align="center"
          mt="2vh"
        >
          {t("prices")}
        </Typography>
        <Divider
          sx={{
            mb: "2vh",
            width: "70vw",
            backgroundColor: "black",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />

        <Paper
          sx={{
            mb: 3,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={4}>
              <LicenseComponent content={"Daily"} photo={daily} />
            </Grid>
            <Grid item xs={12} md={4}>
              <LicenseComponent content={"Doba"} photo={carp_day} />
            </Grid>
            <Grid item xs={12} md={4}>
              <LicenseComponent content={"Spinning"} photo={spinning} />
            </Grid>
          </Grid>
          <Box pb={3}>
            <Typography
              variant="h5"
              sx={{
                marginTop: "2vh",
                textAlign: "center",
              }}
            >
              {t("pricesLine1")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                marginTop: "1vh",
                textAlign: "center",
              }}
            >
              {t("pricesLine2")}
            </Typography>{" "}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default Prices;
