const routes = {
  home: "home",
  galery: "galeria",
  events: "aktualnosci",
  pricing: "cennik",
  stations: "stations",
  booking: "rezerwacje",
  termsOfUse: "termsOfUse",
  contact: "contact",
};

export default routes;
