import routes from "../../consts/routes";
import palette from "../../consts/palette";
import config from "../../consts/config";
import axios, { AxiosError } from 'axios';

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";

interface FormInterface {
  email: string;
  topic: string;
  message: string;
}

function Booking() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormInterface>({
    email: "",
    topic: "",
    message: "",
  } as FormInterface);

  const [formStateErrors, setFormStateErrors] = useState<FormInterface>({
    email: "emailInvalid",
    topic: "topicInvalid",
    message: "messageInvalid",
  } as FormInterface);

  const emailValidator = (value: string) => {
    setFormState({ ...formState, email: value });
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      setFormStateErrors({ ...formStateErrors, email: "emailInvalid" });
    } else {
      setFormStateErrors({ ...formStateErrors, email: "" });
    }
  };

  const topicValidator = (value: string) => {
    setFormState({ ...formState, topic: value });
    if (value.length === 0) {
      setFormStateErrors({ ...formStateErrors, topic: "topicInvalid" });
    } else {
      setFormStateErrors({ ...formStateErrors, topic: "" });
    }
  };
  const messageValidator = (value: string) => {
    setFormState({ ...formState, message: value });
    if (value.length === 0) {
      setFormStateErrors({ ...formStateErrors, message: "messageInvalid" });
    } else {
      setFormStateErrors({ ...formStateErrors, message: "" });
    }
  };

  const onSubmitClick = async () => {
    if (formState.email && formState.topic && formState.message) { // Ensure fields are not empty
      try {
        const response = await axios.post( config.apiIp +'/reservation/createContact', {
          email: formState.email,
          subject: formState.topic,
          message: formState.message,
        });
        console.log('Email sent successfully');
        // Reset form or show success message
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // error is now typed as AxiosError, and error.response is available
          console.error('Failed to send email1', error.response ? error.response.data : error.message);
        } else {
          // error is not an AxiosError, handle differently or throw again
          console.error('An unexpected error occurred:', error);
        }
      }
    }
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: "100vh",
      }}
    >
      <Typography
        sx={{ typography: { xs: "h4", md: "h2" } }}
        align="center"
        mt="2vh"
      >
        {t("contact")}
      </Typography>
      <Divider
        sx={{
          mb: "2vh",
          width: "70vw",
          backgroundColor: "black",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "80vw",
          py: 3,
          borderRadius: "25px",
          margin: "auto",
          my: "5vh",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h4" } }}
                my={2}
              >
                {t("warder")}
              </Typography>
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
              >
                Tadeusz
              </Typography>
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
              >
                <PhoneIcon style={{ verticalAlign: "middle" }} />
                693 823 261
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  width: "60%",
                  alignSelf: "center",
                  backgroundColor: "black",
                }}
              />
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h4" } }}
                my={2}
              >
                {t("owner")}
              </Typography>
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
              >
                Tomasz
              </Typography>
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
              >
                <PhoneIcon style={{ verticalAlign: "middle" }} />
                604 443 204
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  width: "60%",
                  alignSelf: "center",
                  backgroundColor: "black",
                }}
              />
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h4" } }}
                my={2}
              >
                <EmailIcon sx={{ mr: 0.5 }} />
                E-mail
              </Typography>
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
              >
                strupino.jezioro@gmail.com
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  width: "60%",
                  alignSelf: "center",
                  backgroundColor: "black",
                }}
              />
              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
                my={2}
              >
                <Link
                  href="https://www.facebook.com/JezioroStrupino/"
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookTwoToneIcon />
                  {t("Facebook")}
                </Link>
              </Typography>

              <Typography
                align="center"
                sx={{ typography: { xs: "h6", md: "h5" } }}
                my={2}
              >
                <Link
                  href="https://www.facebook.com/JezioroStrupino/"
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon color="error" />
                  {t("YouTube")}
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              required
              error={formStateErrors.email !== ""}
              id="email"
              label="E-Mail"
              variant="filled"
              sx={{ mb: 3, mx: 5 }}
              onChange={(event) => emailValidator(event.target.value)}
              value={formState.email}
              helperText={t(formStateErrors.email)}
            />
            <TextField
              required
              error={formStateErrors.topic !== ""}
              id="temat"
              label={t("topic")}
              variant="filled"
              sx={{ mb: 3, mx: 5 }}
              onChange={(event) => topicValidator(event.target.value)}
              value={formState.topic}
              helperText={t(formStateErrors.topic)}
            />
            <TextField
              required
              error={formStateErrors.message !== ""}
              id="message"
              label={t("message")}
              variant="filled"
              multiline
              rows={12}
              sx={{ mb: 3, mx: 5 }}
              onChange={(event) => messageValidator(event.target.value)}
              value={formState.message}
              helperText={t(formStateErrors.message)}
            />
            <Button
              variant="contained"
              color="success"
              sx={{ width: "80%", alignSelf: "center" }}
              disabled={Object.keys(formStateErrors).includes("")}
              onClick={() => onSubmitClick()}
            >
              {t("sendMessage")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}

export default Booking;
